import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../auth/RoleManager";
import Downloader from "../components/UploaderAndDownloader/Downloader";
import Uploader from "../components/UploaderAndDownloader/Uploader";
import {
  fetchAllTags,
  selectShipId,
  updateTagsMetaForShip,
} from "../slices/TagNamesSlice";
import TagsMetaTable from "../components/UpdateTagsMeta/TagsMetaTable";
import Loading from "../components/Loading/Loading";
import { sliceStatus } from "../util/Consts";
import { ShipList } from "../components/ShipSelector/ShipSelector";

const ManageTagMetaPage = () => {
  const dispatch = useDispatch();
  const {
    ships: shipList,
    fullTagMeta,
    selectedShipId,
    status,
    error
  } = useSelector((state) => state.tags);
  const metaObject = fullTagMeta?.[selectedShipId];

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedData, setUploadData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredData, setFilteredData] = useState({});
  const [filteredUploadData, setFilteredUploadData] = useState({});
  const [isWaiting, setWaiting] = useState(false);

  const { role } = useSelector((state) => state.auth);
  const [authorized] = useState(
    [ROLES.SUPERUSER.name, ROLES.ADMIN.name, ROLES.AUTHOR.name].includes(role)
  );

  // #region UseCallback
  const filterData = useCallback((data, searchKeyword) => {
    if (!data || !searchKeyword) {
      return data;
    }

    const filteredData = {};
    for (const key in data) {
      if (key.includes(searchKeyword)) {
        filteredData[key] = data[key];
      }
    }
    return filteredData;
  }, []);

  const handleShipIdSelect = useCallback(
    (shipId) => {
      dispatch(selectShipId(shipId));
    },
    [dispatch]
  );

  const uploadTagClickCallback = useCallback(
    (updatedTagMeta) => {
      if (uploadedData) {
        const newObject = { ...uploadedData };
        newObject[updatedTagMeta.tagName] = {
          ...newObject[updatedTagMeta.tagName],
          ...updatedTagMeta,
        };

        setUploadData(newObject);
      } else {
        return dispatch(
          updateTagsMetaForShip({
            shipId: selectedShipId,
            data: { [updatedTagMeta.tagName]: updatedTagMeta },
          })
        );
      }
    },
    [dispatch, selectedShipId, uploadedData]
  );

  const uploadClicked = useCallback(() => {
    setIsUploading(true);
  }, []);

  const dataUploaded = useCallback((data) => {
    if (data) {
      setUploadData(data);
    } else {
      setIsUploading(false);
    }
  }, []);

  const uploadAll = useCallback(async () => {
    dispatch(
      updateTagsMetaForShip({
        shipId: selectedShipId,
        data: uploadedData,
        useDefault: true,
      })
    );
    setUploadData(null);
  }, [uploadedData, selectedShipId, dispatch]);

  const handleCancel = useCallback(() => {
    setIsUploading(false);
    setUploadData(null);
  }, []);

  const handleSaveAll = useCallback(async () => {
    setWaiting(true);

    await uploadAll();
    handleCancel();

    setWaiting(false);
  }, [handleCancel, uploadAll]);

  const onKeywordChange = useCallback((e) => {
    setSearchKeyword(e.target.value);
  }, []);

  // #endregion

  // #region Section UseEffects
  useEffect(() => {
    if (!shipList) {
      dispatch(fetchAllTags());
    }
  }, [dispatch]);

  useEffect(() => {
    if (uploadedData) {
      setFilteredUploadData(filterData(uploadedData, searchKeyword));
    }
  }, [uploadedData, searchKeyword, filterData]);

  useEffect(() => {
    setFilteredData(filterData(metaObject?.tagList, searchKeyword));
  }, [metaObject, searchKeyword, filterData]);

  // #endregion

  // #region return render
  return (
    <div className="container">
      <div className="mx-3 row">
        <div className="w-100 col-md-3 mb-3 ms-1">
          <ShipList
            shipList={shipList}
            onShipSelect={handleShipIdSelect}
            selectedShip={selectedShipId}
            isDisabled={isUploading}
          ></ShipList>
        </div>
        {selectedShipId && (
          <div className="d-flex mb-3">
            <div className="container p-0 ms-0 mw-100">
              <div className="row m-0 g-2 w-100 mw-100 d-flex flex=row justify-content-md-start justify-content-center">
                <input
                  className="m-1 col-10 col-md form-control"
                  onKeyUp={onKeywordChange}
                  placeholder="search tags"
                />
                <div className="col-lg-1 col-10 miw-140-px col-md-2 col-2 my-1 mx-0">
                  <Downloader
                    downloadData={filteredData}
                    classNames="form-control"
                  />
                </div>
                {authorized && (
                  <div className="col-lg-1 miw-140-px col-md-2 col-10  my-1 mx-0">
                    <Uploader
                      dataUploadedCallback={dataUploaded}
                      uploadClickedCallback={uploadClicked}
                      isButtonsDisabled={isUploading}
                      onErrorCallback={handleCancel}
                      classNames="form-control"
                    />
                  </div>
                )}

                {uploadedData && (
                  <>
                    <div className="col-lg-1 col-md-2 col-sm-4  my-1 mx-0">
                      <button
                        type="button"
                        className="btn btn-primary form-control text-nowrap "
                        onClick={handleSaveAll}
                        disabled={isWaiting}
                      >
                        <Loading
                          className={"mx-3"}
                          isLoading={isWaiting}
                          animationHeight={"100%"}
                          animationWidth={"22px"}
                          circleColor="#fff"
                          animationOnly={true}
                          scaleAnimation={"1.2"}
                        >
                          Save All
                        </Loading>
                      </button>
                    </div>
                    <div className="col-lg-1 col-md-2 col-sm-4 my-1 mx-0">
                      <button
                        type="button"
                        className="btn btn-primary col-lg-2 form-control"
                        onClick={handleCancel}
                        disabled={isWaiting}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
                {/* </div> */}
              </div>
            </div>
          </div>
        )}

        <div className="table-responsive col-12">
          {selectedShipId && (status === sliceStatus.FAILED ? error : <Loading className={"d-flex justify-content-center align-items-center"}
            isLoading={status === sliceStatus.LOADING}
            animationHeight={"100%"}
            animationWidth={"22px"}
            circleColor="#000"
            scaleAnimation={"1.2"} >
            <TagsMetaTable
              className="table-component"
              asyncEdit={!!uploadedData}
              tableData={uploadedData ? filteredUploadData : filteredData}
              updateClickCallback={uploadTagClickCallback}
            />
          </Loading>)}
        </div>
      </div>
    </div>
  );
  // #endregion
};

export default ManageTagMetaPage;