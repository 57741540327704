import { useCallback } from "react";
import {  convertSimpleJsonToCSVString, download } from "../../util/util";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const Downloader = ({
  isButtonsDisabled,
  downloadData,
  downloadClickedCallback,
  classNames,
}) => {
  const [fileType, setFileType] = useState("json");
  const [isToggled, setToggled] = useState(false);

  const handleDownload = useCallback(async () => {
    /*
        if (window.self === window.top && window.showSaveFilePicker) {
          await writeFile(data);
          alert("Download Complete.");
        } else {
          download(data);
        }
        */
    downloadClickedCallback?.();
    download(
      fileType === "json"
        ? JSON.stringify(downloadData)
        : convertSimpleJsonToCSVString(Object.values(downloadData)),
      `data.${fileType}`,
      fileType !== "json" && "plain/text" 
    );
  }, [downloadData, downloadClickedCallback, fileType]);

  const handleFileTypeChange = useCallback((e) => {
    setFileType(e.target.name);
  }, []);

  return (
    <div className="input-group">
      <button
        type="button"
        className={`btn btn-primary text-nowrap ${classNames}`}
        disabled={isButtonsDisabled}
        onClick={handleDownload}
      >
        <span className="me-1">{fileType.toUpperCase()} </span>
        <i className="fa fa-download"></i>
      </button>
      <Dropdown toggle={() => setToggled((state) => !state)} isOpen={isToggled}>
        <DropdownToggle
          style={{ borderBottomLeftRadius: "0", borderTopLeftRadius: "0" }}
          caret
        />
        <DropdownMenu>
          <DropdownItem
            className={`w-100 ${fileType === "json" && "active"}`}
            name="json"
            onClick={handleFileTypeChange}
          >
            JSON (.json)
          </DropdownItem>
          <DropdownItem
            className={`w-100 ${fileType === "csv" && "active"}`}
            name="csv"
            onClick={handleFileTypeChange}
          >
            CSV (.csv)
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Downloader;
