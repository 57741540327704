import { useEffect, useState, useCallback } from "react";
import UserRow from "../components/Users/UserRow";
import Pagination from "../components/Pagination/Pagination";
import { ROLES } from "../auth/RoleManager";
import "../components/Users/users.css";
import Loading from "../components/Loading/Loading";
import InstanceManager from "../auth/auth";
import { Link } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [role, setRole] = useState("all");
  const [renderingUsers, setRenderingUsers] = useState([]);
  const [keyword, setKeyword] = useState("");

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await InstanceManager.getInstance().get("/auth/users");
      if (response.status === 200) {
        setUsers(response.data);
        setFilteredUsers(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const updateUserRole = useCallback(
    async (user) => {
      try {
        const response = await InstanceManager.getInstance().put("/auth/users/update", user);
        if (response.status === 200) {
          setUsers(
            users.map((prevUser) =>
              prevUser.email === user.email ? user : prevUser
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [users]
  );

  useEffect(() => {
    setLoading(true);

    setFilteredUsers(
      users.filter(
        (user) =>
          (role === "all" || role === user.role) &&
          (user.role !== ROLES.SUPERUSER.name) &&
          ((!keyword && keyword !== "") || user.email.includes(keyword))
      )
    );

    setLoading(false);
  }, [users, role, keyword]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <div className="ms-1 mt-3">Search users:</div>
        <Link to="/register" className="btn btn-primary m-2"><i className="fa-solid fa-user-plus me-2"></i>Add User</Link>
      </div>
      <div className="input-group mb-3">
        <select
          disabled={isLoading}
          className="form-select mw-120-px"
          onChange={(e) => {
            setRole(e.target.value);
          }}
        >
          <option value={"all"}>All roles</option>
          {Object.entries(ROLES).map(
            ([key, { name }]) =>
              name !== ROLES.SUPERUSER.name && (
                <option value={name} key={key}>
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </option>
              )
          )}
        </select>
        <input
          disabled={isLoading}
          type="text"
          className="form-control m-w-200-px"
          placeholder="Find Users"
          aria-label="Find Users"
          aria-describedby="button-search"
          onKeyUp={(e) => {
            setKeyword(e.target.value);
          }}
        />
      </div>

      <Loading
        className="w-100 d-flex justify-content-center align-items-center"
        isLoading={isLoading}
        animationWidth={"50px"}
        animationHeight={"50px"}
      >
        {filteredUsers?.length > 0 ? (
          <>
            <div className="custom-table data-table mw-100 users-table">
              <table className="table table-hover table-responsive table-bordered text-center">
                <thead className="sticky-top bg-white top-0">
                  <tr className="user-data-row">
                    <th>Email</th>
                    <th>Role</th>
                    <th>Save</th>
                    <th>Cancel</th>
                  </tr>
                </thead>
                <tbody className="user-data-body">
                  {renderingUsers.map(
                    (user) =>
                      <UserRow
                        key={user.email}
                        user={user}
                        updateUserRole={updateUserRole}
                      />
                  )}
                </tbody>
              </table>
            </div>
            <div className="container">
              <div className="row my-3 justify-content-between align-items-center">
                <Pagination
                  withElementsPerPage={true}
                  withJump={true}
                  data={filteredUsers}
                  setRenderingData={setRenderingUsers}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="text-center w-100">
            <p>No data Found</p>
          </div>
        )}
      </Loading>
    </div>
  );
};

export default Users;
