import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import {
    Route,
    Routes
} from "react-router-dom";
import { useSelector } from 'react-redux';
import RequireAuth from './requireAuth';
import Login from './pages/login';
import Home from './pages/Home';
import { LoadScript } from '@react-google-maps/api';

export const App = () => {
    /*static displayName = App.name;*/
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    return (

        <Layout>
                <Routes>

                    <Route path="/" element={<Home />} index={true} />
                    <Route path="/login" element={<Login />} />
                    {AppRoutes.map((route, index) => {
                        return <Route
                            key={index}
                            path={route.path}
                            element={<RequireAuth
                                isAuthenticated={isAuthenticated}
                                redirectTo='/login'
                                element={route.element}
                            >

                            </RequireAuth>}
                        >

                        </Route>
                    })}
                </Routes>
        </Layout>
    );
};
export default App;