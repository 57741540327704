import React, { useCallback, useMemo, useState } from "react";
import MessagesTable from "../components/shipDataTable/shipDataTable";
import { ShipList } from "../components/ShipSelector/ShipSelector";
import TagList from "../components/tagNameSelector/tagNameSelector";
import TimeRangeFilter from "../components/TimeRangeFilter/TimeRangeFilter";
import Sidebar from "../components/Sidebar/Sidebar";
import FilterTree from "../components/FilterTree/FilterTree";
import "../components/ShipDetails/ShipDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { clearData, setTimeRange } from "../slices/shipDataSlice";
import { VIEW_POP_UP_ELEMENT_IDS } from "../util/Consts";
import { editState } from "../slices/viewPopUpSlice";

const ShipDataTable = () => {
  const dispatch = useDispatch();
  const { [VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA]: data } = useSelector(state => state.viewPopUp.data);
  const { startTime, endTime } = useSelector(
    (state) => state.shipData.timeRange
  );
  const { treeFilteredShips } = useSelector((state) => state.tags);
  const {dataKeys} = useMemo(() => data || {}, [data]);

  const handleDataKeysChange = useCallback((newDataKeys) => {
    dispatch(editState({key: VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA, data: {...data, dataKeys: newDataKeys}}));
  }, [dispatch, data]);
  
  const onTimeRangeChanged = useCallback(
    (updatedTimeRange) => {
      dispatch(clearData());
      dispatch(setTimeRange(updatedTimeRange));
    },
    [dispatch]
  );

  return (
    <div className="row vw-100">
      <div className="col-lg-3  d-none d-lg-block filter-side-bar">
        <FilterTree />
      </div>
      <div className="col-lg-9 col-12 ">
        <div className="row mb-3">
          <div className="col-md-6 mb-2 mb-md-0">
            <ShipList shipList={treeFilteredShips}></ShipList>
          </div>
          <div className="col-md-6">
            <TagList />
          </div>
        </div>

        <TimeRangeFilter
          startTime={startTime}
          endTime={endTime}
          onFilterClick={onTimeRangeChanged}
        ></TimeRangeFilter>

        <div className="row mb-3">
          <div className="table-responsive">
            <MessagesTable
              className="table-component"
              keys={dataKeys}
              onDataKeysChange={handleDataKeysChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ShipDataTable };
