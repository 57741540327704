import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeZone: "UTC"
};

const timeZoneReducer = createSlice({
  name: "timeFormat",
  initialState,
  reducers: {
    setUseLocalTimeZone: (state, action) => {
      const timeZone = action.payload;
      state.timeZone = timeZone;
    },
  },
});

export const { setUseLocalTimeZone } = timeZoneReducer.actions;

export default timeZoneReducer.reducer;
