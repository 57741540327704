import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpen } from "../../slices/viewPopUpSlice";
import "./ViewPopUp.css"  
import ViewPopUpContent from "./ViewPopUpContent";

const ViewPopUp = ({ className, style = {}, width = 40 }) => {
    const dispatch = useDispatch();
    const { isOpen } = useSelector(state => state.viewPopUp);

    const handleClose = useCallback(() => {
        dispatch(setIsOpen(false));
    }, [dispatch]);

    return <div className={`d-flex flex-column view-popup-content rounded-1 ${isOpen ? "p-2 me-2 mb-2 border shadow" : "hide m-0 p-0"} `} style={{ width: isOpen ? `${width}vw` : 0, ...(style || {}) }}>
        <div className="d-flex justify-content-end">
            <button className="btn btn-secondary" onClick={handleClose}>
                <i className={`fa fa-close`}></i>
            </button>
        </div>
        <div className={`container-fluid ${className || ""}`}>
            <ViewPopUpContent />
        </div>
    </div>
}

export default ViewPopUp;