import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import './DNV.css';
import { dataChannelKeys, dataChannelDescriptions } from "./dnvConst.js";
import EditLocalID from './EditLocalID';
import TagList from '../tagNameSelector/tagNameSelector';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

const DNVChannelForm = ({ rowData, onSave, onCancel, isNew, isLoading }) => {
    const [editedData, setEditedData] = useState({});
    const { selectedShip, availableTags } = useSelector(state => state.dnv);
    const { filteredTagsByShip: { [selectedShip]: { tagList } } } = useSelector(state => state.tags);
    const [tagChanged,setTagChanged]=useState(false);

    const getTagList = useMemo(() => {
        const selectedTag = tagList[_.get(rowData, dataChannelKeys["shortID"], "")];
        if (selectedTag) {
            return selectedTag ?
                { ...tagList, [selectedTag.tagName]: selectedTag } :
                tagList;
        }
        return availableTags;
    }, [rowData, tagList, availableTags]);

    const handleChange = useCallback((value, path, updateOldObject, useNull) => {
        if (updateOldObject) {
            _.set(editedData, path, value || (useNull?null:""))
            return;
        }

        const updatedData = _.cloneDeep(editedData);
        _.set(updatedData, path, value ||  (useNull?null:""))
        setEditedData(updatedData);
    }, [editedData]);

    const selectedTagName = useCallback((key) => {
       return _.get(editedData, dataChannelKeys[key], "");
    }, [editedData])

    const handleLocalIDChange = useCallback((value) => {
        handleChange(value, dataChannelKeys.localID);
    }, [handleChange]);

    const handleShortIDChange = useCallback((value) => {
        setTagChanged(value &&_.get(editedData,dataChannelKeys.shortID)!==_.get(rowData,dataChannelKeys.shortID));
        handleChange(value, dataChannelKeys.shortID, true, true);
    }, [handleChange, editedData,rowData]);

    const handleSave = useCallback(() => {
        let shortID, localID;
        if ((shortID = _.get(editedData, dataChannelKeys['shortID'])) && shortID !== "" && (localID = _.get(editedData, dataChannelKeys['localID'])) && localID !== "") {
            onSave(editedData);
        } else {
            NotificationManager.error("Required fields cannot be empty.");
        }
    }, [editedData, onSave, rowData]);

    useEffect(() => {
        setEditedData(_.cloneDeep(rowData));
    }, [rowData]);

    const localIDParts = useMemo(() => {
        const localId = _.get(rowData, dataChannelKeys.localID, "");
        const dnv_part = "/dnv-v2/vis-3-6a/"
        const meta_part = "/meta/"
        const parts = localId.replace(dnv_part, "").split(meta_part)
        return { "primary_item": parts[0], "quantity": parts[1] }
    }, [rowData]);

    const isFormValid=useCallback(()=>{
        return isLoading || (JSON.stringify(editedData) === JSON.stringify(rowData)&& !tagChanged) 
    },[isLoading,editedData,rowData])

    return (
        <div className="edit-form">
            <form>
                <div className="form-group">
                    {Object.keys(dataChannelKeys).map((key) => (
                        key === "localID" ?
                            <EditLocalID key={key} parts={localIDParts} isDisabled={!isNew} onChange={handleLocalIDChange} />
                            :

                            <label key={key} className='px-3 py-1 col-4'>

                                {key === "shortID" ? (
                                    <>
                                        <span>{dataChannelDescriptions[key]} * </span>
                                        <TagList
                                            tagList={getTagList}
                                            currentTagName={selectedTagName(key)}
                                            tagNameSelectedCallback={handleShortIDChange}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <span>{dataChannelDescriptions[key]}</span>
                                        <input className="form-control"
                                            type="text"
                                            disabled={key === "shortID" ? true : false}
                                            value={_.get(editedData, dataChannelKeys[key], "") || ""}
                                            onChange={(e) => handleChange(e.target.value, dataChannelKeys[key])}
                                        />
                                    </>
                                )}
                            </label>
                    ))}
                </div>
                <div className="modal-footer">
                    <button type="button" disabled={isFormValid()} className="btn btn-primary" onClick={handleSave}>Save</button>
                    <button type="button" disabled={isLoading} className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default DNVChannelForm;