export const dataChannelKeys = {
    localID: 'dataChannelID.localID',
    shortID: 'dataChannelID.shortID',
    namingRule: 'dataChannelID.nameObject.namingRule',
    type: 'property.dataChannelType.type',
    updateCycle: 'property.dataChannelType.updateCycle',
    calculationPeriod: 'property.dataChannelType.calculationPeriod',
    formatType: 'property.format.type',
    formatRestriction: 'property.format.restriction',
    rangeHigh: 'property.range.high',
    rangeLow: 'property.range.low',
    unitSymbol: 'property.unit.unitSymbol',
    unitQuantityName: 'property.unit.quantityName',
    qualityCoding: 'property.qualityCoding',
    alertPriority: 'property.alertPriority',
    name: 'property.name',
    remarks: 'property.remarks',
    status: 'property.status',
    quantityName: 'property.quantityName',
    dataType: 'property.dataType',
    rangeMax: 'property.rangeMax',
    alarmHigh: 'property.alarmHigh',
    alarmHighHigh: 'property.alarmHighHigh',
    channelType: 'property.channelType',
    channelUpdateCycle: 'property.channelUpdateCycle',
    rangeMin: 'property.rangeMin',
    alarmLow: 'property.alarmLow',
    alarmLowLow: 'property.alarmLowLow',
    alarmDigitalValue: 'property.alarmDigitalValue',
    channelCalculationPeriod: 'property.channelCalculationPeriod',
};

export const dataChannelDescriptions = {
    localID: 'Local ID',
    shortID: 'Short ID',
    namingRule: 'Naming Rule',
    type: 'Data Channel Type',
    updateCycle: 'Update Cycle',
    calculationPeriod: 'Calculation Period',
    formatType: 'Format Type',
    formatRestriction: 'Format Restriction',
    rangeHigh: 'Range High',
    rangeLow: 'Range Low',
    unitSymbol: 'Unit Symbol',
    unitQuantityName: 'Unit Quantity Name',
    qualityCoding: 'Quality Coding',
    alertPriority: 'Alert Priority',
    name: 'Name',
    remarks: 'Remarks',
    status: 'Status',
    quantityName: 'Quantity Name',
    dataType: 'Data Type',
    rangeMax: 'Range Max',
    alarmHigh: 'Alarm High',
    alarmHighHigh: 'Alarm High High',
    channelType: 'Channel Type',
    channelUpdateCycle: 'Channel Update Cycle',
    rangeMin: 'Range Min',
    alarmLow: 'Alarm Low',
    alarmLowLow: 'Alarm Low Low',
    alarmDigitalValue: 'Alarm Digital Value',
    channelCalculationPeriod: 'Channel Calculation Period',
};

