import './Footer.css'
import TimeZoneChanger from './timeZoneChanger/TimeZoneChanger';

const Footer = () => {
  return (
    <footer className="px-2 py-4 border-top">
      <div className="container">
        <div className="row">
          {process.env.REACT_APP_VERSION &&
            <div className="col pt-3 d-flex justify-content-start small">
              v{process.env.REACT_APP_VERSION}
            </div>
          }
          <div className="col d-flex justify-content-end small">
            <TimeZoneChanger />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
