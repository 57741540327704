import Cookies from "js-cookie";

const ACCESS_TOKEN_COOKIE_NAME = 'access_token';
const REFRESH_TOKEN_COOKIE_NAME = 'refresh_token';

export const setAccessTokenInCookies = (accessToken) => Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, { expires: 1, sameSite: 'strict' });

export const setRefreshTokenInCookies = (refreshToken) => Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, { expires: 7, sameSite: 'strict' });

export const getAccessTokenFromCookies = () => Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

export const getRefreshTokenFromCookies = () => Cookies.get(REFRESH_TOKEN_COOKIE_NAME);

export const removeTokensFromCookies = () => {
    Cookies.remove(REFRESH_TOKEN_COOKIE_NAME, { sameSite: 'strict' });
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME, { sameSite: 'strict' });
};