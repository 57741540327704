import React, { useCallback, useState } from "react";
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import { Await, Link } from "react-router-dom";
import "./NavMenu.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import RequireAuthorization from "./Authorization/RequireAuthorization";
import { ROLES } from "../auth/RoleManager";
import InstanceManager from "../auth/auth";

const NavMenu = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const { token, email } = useSelector((state) => state.auth);

  const toggleNavbar = useCallback(() => {
    setCollapsed((collapsed) => !collapsed);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <header>
      <Navbar
        className="navbar-expand-md navbar-toggleable-md ng-white border-bottom box-shadow mb-3"
        container
        light
      >
        <NavbarBrand tag={Link} to="/">
          Torghatten ShipData
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse
          className="d-lg-inline-flex flex-lg-row-reverse"
          isOpen={!collapsed}
          navbar
        >
          <ul className="navbar-nav flex-grow">
            <NavItem className="d-flex justify-content-center">
              <NavLink
                tag={Link}
                className="text-dark w-100 px-3 me-md-2 me-0 rounded-1"
                to="/"
              >
                Home
              </NavLink>
            </NavItem>

            {token ? (
              <>
                <UncontrolledDropdown className="d-flex justify-content-center">
                  <DropdownToggle className="dropdown-selector w-100 text-start px-3" caret>
                    ShipData
                  </DropdownToggle>

                  <DropdownMenu
                    className="dropdown-menu"
                    aria-labelledby="tagMetaDropdown"
                  >
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                      <NavLink
                        tag={Link}
                        className="text-dark w-100 py-2 text-center"
                        to="/ship-data-table"
                      >
                        Table
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                      <NavLink
                        tag={Link}
                        className="text-dark w-100 py-2 text-center"
                        to="/ship-data-map"
                      >
                        Map
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="d-flex justify-content-center">
                  <DropdownToggle
                    className="dropdown-selector w-100 text-start px-3"
                    caret
                  >
                    Manage
                  </DropdownToggle>

                  <DropdownMenu
                    className="dropdown-menu"
                    aria-labelledby="tagMetaDropdown"
                  >
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                      <NavLink
                        tag={Link}
                        className="text-dark w-100 py-2 text-center"
                        to="/update-tag-meta"
                      >
                        TagMeta
                      </NavLink> 
                    </DropdownItem>
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                      <RequireAuthorization roles={[ROLES.ADMIN.name, ROLES.AUTHOR.name, ROLES.SUPERUSER.name]}>
                        <NavLink
                          tag={Link}
                          className="text-dark w-100 py-2 text-center"
                          to="/data-sets"
                        >
                          DataSets
                        </NavLink>
                      </RequireAuthorization> 
                    </DropdownItem>
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                        <NavLink
                          tag={Link}
                          className="text-dark w-100 py-2 text-center"
                          to="/ship-metadata"
                        >
                          Ship MetaData
                        </NavLink>
                      </DropdownItem>
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                      <NavLink
                        tag={Link}
                        className="text-dark w-100 py-2 text-center"
                        to="/dnv"
                      >
                        DNV
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem className="m-0 p-0 w-100 border-0">
                        <RequireAuthorization roles={[ROLES.ADMIN.name, ROLES.SUPERUSER.name]}>
                          <NavLink
                            tag={Link}
                            className="text-dark w-100 py-2 text-center"
                            to="/users"
                          >
                            Users
                          </NavLink>
                        </RequireAuthorization>
                      </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown>
                  <DropdownToggle className="mx-2 bg-dark w-md-100">
                    <i className="fa fa-user d-none d-md-inline"></i>
                    <div className="d-inline d-md-none w-md-100">
                      {email}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <div className="w-100 px-2">
                      <Link to={"/settings"} className="text-black text-decoration-none btn w-100 nav-dropdown-link">
                        Settings
                      </Link>
                    </div>
                    <DropdownItem divider />
                    <NavItem className="d-flex mx-2 justify-content-center">
                      <Button
                        color="primary"
                        tag={Link}
                        onClick={handleLogout}
                        className="mx-1 w-100"
                      >
                        {"Logout"}
                      </Button>
                    </NavItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            ) : (
              <NavItem className="d-flex justify-content-center">
                <NavLink
                  className="text-dark w-100 px-3 ms-md-2 ms-0 rounded"
                  tag={Link}
                  to="/login"
                >
                  Log In
                </NavLink>
              </NavItem>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
