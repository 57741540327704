import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { formatDate } from "../../util/util";

const ShipDataMap = () => {
  const {
    data: allData,
    paths,
    selectedDataset: datasetName,
    selectedShip,
  } = useSelector((state) => state.dataset);
  const { timeZone } = useSelector((state) => state.timeZone);
  const shipPath = paths[selectedShip];
  const [currentMap, setCurrentMap] = useState(null);
  const circlesRef = useRef([]);
  const polylinesRef = useRef([]);
  const markersRef = useRef([]);

  const { isLoaded } = useJsApiLoader({ 
    googleMapsApiKey: /*process.env.REACT_APP_GOOGLE_MAPS_API_KEY */ "AIzaSyAcC8fcR68RrN26G4fPQ7OecjSawPoiZOM"
  });

  const circleOptions = useMemo(() => {
    return {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      radius: 7.5,
    };
  }, []);

  const polylineOptions = useMemo(() => {
    return {
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    };
  }, []);

  const mapStyle = useMemo(() => {
    return {
      height: "100%",
      width: "100%",
    };
  }, []);

  const googleMapOptions = useMemo(() => {
    return {
      center: { lat: 0, lng: 0 },
      zoom: 3,
      streetViewControl: false,
      mapTypeControl: false,
      options: {
        minZoom: 2,
        maxZoom: 18,
      },
    };
  }, []);

  const createMarker = useCallback(
    (position, text) => {
      const marker = new window.google.maps.Marker({
        map: currentMap,
        position: position,
        label: {
          text: text,
          color: "white",
          fontSize: "8px",
          fontWeight: "bold",
        },
      });
      markersRef.current.push(marker);
    },
    [currentMap]
  );

  const removeMapItems = useCallback((dataset) => {
    dataset.forEach((item) => item.setMap(null));
    dataset.length = 0;
  }, []);

  useEffect(() => {
    if (isLoaded && currentMap) {
      const positionPath = [];

      removeMapItems(circlesRef.current);
      removeMapItems(polylinesRef.current);
      removeMapItems(markersRef.current);

      const dataLength = shipPath?.length;
      const data = allData?.[selectedShip]?.[datasetName] ?? [];
      const bounds = new window.google.maps.LatLngBounds();

      shipPath?.forEach(([lng, lat], index) => {
        const currentData = data[index] ?? null;

        if (currentData) {
          const position = { lat: lat, lng: lng };
          positionPath.push(position);
          const circle = new window.google.maps.Circle({
            ...circleOptions,
            map: currentMap,
            center: position,
          });
          const infoWindow = new window.google.maps.InfoWindow();

          circle.addListener("mouseover", () => {
            infoWindow.setContent(`
                        <dt>Timestamp: ${formatDate(
              true,
              currentData.timestamp,
              timeZone
            )}</dt>
                        <dt>${datasetName}: ${currentData.value}</dt>
                            <dt>Latitude: ${lat}</dt>
                             <dt> Longitude: ${lng} </dt>
                             `);
            infoWindow.setPosition(position);
            infoWindow.open(currentMap, this);
          });

          circle.addListener("mouseout", (e) => {
            infoWindow.close();
          });

          circlesRef.current.push(circle);

          if (index === 0) {
            createMarker(position, "Start");
          }
          if (index === dataLength - 1) {
            createMarker(position, "End");
          }

          bounds.extend(circle.getBounds()?.getCenter());
        }
      });

      if (circlesRef.current.length > 0) {
        currentMap.fitBounds(bounds);
        const polyline = new window.google.maps.Polyline({
          ...polylineOptions,
          path: positionPath,
          map: currentMap,
        });
        polylinesRef.current.push(polyline);
      }
    }
  }, [
    selectedShip,
    currentMap,
    isLoaded,
    shipPath,
    allData,
    datasetName,
    selectedShip,
    timeZone,
  ]);

  const mapCreatedCallback = useCallback((map) => {
    setCurrentMap(map);
  }, []);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={mapStyle}
        options={googleMapOptions}
        onLoad={mapCreatedCallback}
      ></GoogleMap>
    )
  );
};

export default ShipDataMap;
