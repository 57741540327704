import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputWithSuggestions from "../inputWithSuggestions/inputWithSuggestions";
import { sliceStatus } from "../../util/Consts";
import { getAllDataSets} from "../../slices/dataTagNamesSlice";
import Loading from "../Loading/Loading";

const DatasetList = ({ onDatasetSelect, selectedDataset, clearFunction, isDisabled, selectedShip, showAll }) => {
  const dispatch = useDispatch();
  const {
    selectedDataset: defaultSelectedDataset,
    error,
  } = useSelector((state) => state.dataset);
  const { dataSets: datasetList, dataSetStatus: status } = useSelector((state) => state.dataTags);

  const previewData= useMemo(()=>{
    if (selectedShip && !showAll) {
      const newDataSet = [];
       Object.values({ ...datasetList }).forEach((value) => {
        if (value.dataTagList?.some(item => item.imo == selectedShip)) {
          newDataSet.push(value);
        };
      });
      return newDataSet;
    } else return Object.values(datasetList);
  },[datasetList, selectedShip, showAll]);

  const handleSelectDataset = useCallback(
    (dataSet) => {
      onDatasetSelect(dataSet);
    },
    [onDatasetSelect]
  );

  const selectedItem = useMemo(() => {
    return selectedDataset === undefined
      ? defaultSelectedDataset
      : selectedDataset;
  }, [selectedDataset, defaultSelectedDataset]);

  useEffect(() => {
    if (!datasetList || Object.keys(datasetList).length === 0) {
      dispatch(getAllDataSets()); 
    }
  }, [datasetList,dispatch]); 

  if (status === sliceStatus.FAILED) {
    return <div>{error}</div>;
  } else {
    return (
      <Loading
        isLoading={status === sliceStatus.LOADING}
        text="Loading tag names"
        animationWidth={"28px"}
        animationHeight={"28px"}>
        <InputWithSuggestions
          selectedDataList={previewData}
          onSelectSuggestion={handleSelectDataset}
          selectedItem={selectedItem}
          itemKeyField="name"
          itemDescField="description"
          placeHolder="Select a Dataset"
          clearFunction={clearFunction}
          isDisabled={isDisabled}
        />
      </Loading>
    );
  }
};

export default DatasetList;
