import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUseLocalTimeZone } from "../../slices/timeZoneSlice";

const TimeZoneChanger = () => {
  const dispatch = useDispatch();
  const { timeZone } = useSelector((state) => state.timeZone);
  const { timeZone: timeZoneLocal } = Intl.DateTimeFormat().resolvedOptions();

  const handleOnClick = useCallback(
    (event) => {
      const { name } = event.target;
      dispatch(setUseLocalTimeZone(name === "UTC" ? "UTC" : timeZoneLocal));
    },
    [dispatch, timeZoneLocal]
  );

  return (
    <>
      <ul className="nav nav-pills">
        <li className="nav-item">
          <button
            className={`nav-link ${timeZone === "UTC" && "active"}`}
            onClick={handleOnClick}
            name="UTC"
          >
            UTC
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${timeZone !== "UTC" && "active"}`}
            onClick={handleOnClick}
            name="Local"
          >
            Local ({timeZoneLocal})
          </button>
        </li>
      </ul>
    </>
  );
};

export default TimeZoneChanger;
