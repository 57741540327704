import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sliceStatus } from "../util/Consts";
import { updateObjects } from "../util/util";
import { NotificationManager } from "react-notifications";
import InstanceManager from "../auth/auth";
import _ from "lodash";

export const fetchAllTags = createAsyncThunk(
  "tags/fetchAllTags",
  async (forceRetrieve, { getState, dispatch }) => {
    const { fullTagMeta: data = null } = getState().tags;
    if (!forceRetrieve && data && Object.keys(data).length > 0) {
      return data;
    }

    let response;
    try {
      response = await InstanceManager.getInstance().get(`/tagMeta`);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      throw error;
    }
    return response.data;
  }
);

export const updateTagsMetaForShip = createAsyncThunk(
  "tags/updateTagsMetaForShip",
  async ({ shipId, data }, { getState, dispatch }) => {
    const { fullTagMeta } = getState().tags;

    const updatedTags = [];
    const updatedShipMeta = updateObjects(
      fullTagMeta[shipId]?.tagList,
      data,
      (key, val, originalData) => {
        let eq = false;
        Object.entries(originalData[key]).every(([metaKey, metaVal]) => {
          if (val[metaKey] !== metaVal) eq = true;

          return !eq;
        });

        if (eq) updatedTags.push(key);
      }
    );

    try {
      await InstanceManager.getInstance().put(`/update/${shipId}`, {
        tags: Object.values(updatedShipMeta),
        updatedTags,
      });
    } catch (error) {
      NotificationManager.error("Something went wrong");
      throw error;
    }
    return { shipId, meta: updatedShipMeta };
  }
);

const updateOrInsertShipMetaDataObject = createAsyncThunk(
  "ship-metadata/updateOrInsert",
  async ({ data, shipId }, { dispatch }) => {
    try {
      const { status, statusText } = await InstanceManager.getInstance().post(`/${shipId}/metadata`, { metaKey: data.key, metaValue: data?.value });
      if (status < 200 || status >= 300) {
        NotificationManager.error(statusText);
      }
      dispatch(replaceShipMetaData({ shipId, meta: data }));
    } catch (error) {
      NotificationManager.error("Something went wrong.");
    }
  })

const deleteShipMetaDataObject = createAsyncThunk(
  "ship-metadata/delete",
  async ({ data, shipId }, { dispatch }) => {
    try {
      if (data?.key) {
        const { status, statusText } = await InstanceManager.getInstance().delete(`/${shipId}/metadata/${data.key}`);
        if (status < 200 || status >= 300) {
          NotificationManager.error(statusText);
        } else {
          dispatch(removeShipMetaData({ shipId, metaKey: data?.key }))
        }
      }
    } catch (error) {
      NotificationManager.error("Something went wrong.");
    }
  })

const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    fullTagMeta: {},
    ships: null,
    treeFilteredShips: null,
    filteredTagsByShip: {},
    selectedTagname: null,
    selectedShip: null,
    status: "idle",
    error: null,
    selectedShipId: null,
  },
  reducers: {
    selectShipId: (state, action) => {
      state.selectedShipId = action.payload;
    },
    selectTagname: (state, action) => {
      state.selectedTagname = action.payload;
    },
    selectShip: (state, action) => {
      state.selectedShip = action.payload;
    },
    filterShips: (state, action) => {
      const { payload: filter = null } = action || {};

      const filterKeys = Object.keys(filter || {});
      if (filter && filterKeys.length > 0) {
        const shipList = new Set();
        const tagList = {};

        for (const key of filterKeys) {
          const [shipId, , tagName] = key.split("-");
          shipList.add(shipId);
          if (!tagList[shipId]) {
            tagList[shipId] = new Set();
          }
          if (tagName) {
            tagList[shipId].add(tagName);
          }
        }

        for (const [shipId, tagNames] of Object.entries(tagList) || {}) {
          state.filteredTagsByShip[shipId] = {
            tagList: Object.entries(state.fullTagMeta[shipId].tagList).reduce(
              (acc, [key, value]) => {
                if (tagNames.has(value.tagName)) {
                  acc[key] = value;
                }
                return acc;
              },
              {}
            ),
          };
        }

        state.treeFilteredShips = state.ships.filter((ship) =>
          shipList.has(ship.imo)
        );
      } else {
        state.treeFilteredShips = state.ships;
        state.filteredTagsByShip = state.fullTagMeta;
      }
    },
    removeShipMetaData: (state, { payload: { shipId, metaKey } }) => {
      const metadata = state.filteredTagsByShip?.[shipId]?.ship?.metadata;

      if (metadata?.[metaKey]) {
        const newMetadata = _.cloneDeep(metadata)
        delete newMetadata?.[metaKey];

        state.filteredTagsByShip[shipId].ship.metadata = newMetadata;
      }
    },
    replaceShipMetaData: (state, { payload: { shipId, meta } }) => {
      const shipDetails = state.filteredTagsByShip?.[shipId]?.ship;
      if (!shipDetails)
        return;

      let metadata = shipDetails?.metadata;

      if (!metadata)
        metadata = {};

      metadata[meta.key] = meta.value;
      shipDetails.metadata = metadata;
      
      state.filteredTagsByShip = _.cloneDeep(state.filteredTagsByShip);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTags.pending, (state) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        state.status = sliceStatus.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        const tagMeta = Object.values(action.payload ?? {}).reduce(
          (shipAcc, current) => {
            const { ship, tagList } = current;

            const tagMap = Object.values(tagList ?? {}).reduce(
              (tagAcc, currentTag) => {
                tagAcc[currentTag.tagName] = currentTag;
                return tagAcc;
              },
              {}
            );
            shipAcc[ship.imo] = { ship: ship, tagList: tagMap };
            return shipAcc;
          },
          {}
        );
        state.fullTagMeta = tagMeta;

        state.ships = Object.values(tagMeta).map((item) => item.ship);

        state.status = sliceStatus.SUCCEEDED;
        tagsSlice.caseReducers.filterShips(state, null);
      })
      .addCase(updateTagsMetaForShip.fulfilled, (state, action) => {
        const { shipId, meta } = action.payload;
        const newObject = { ...state.fullTagMeta };
        newObject[shipId] = { ...newObject[shipId], tagList: meta };
        state.fullTagMeta = newObject;
      });
  },
});

export const {
  selectTagname,
  selectShip,
  filterShips,
  selectShipId,
  setElementsPerPage,
  setPage,
  setSearchKeyword,
  updateTagsMeta,
  removeShipMetaData,
  replaceShipMetaData
} = tagsSlice.actions;

export { updateOrInsertShipMetaDataObject, deleteShipMetaDataObject };

export default tagsSlice.reducer;
