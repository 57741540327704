import { useCallback } from "react";
import DialogBox from "../DialogComponent/Dialog";


const DataSetRow = ({ shipId, keysString, onEditCallback, onDeleteCallback }) => {

    const onEdit = useCallback(() => {
        onEditCallback?.({ shipId: shipId, keysString: keysString });
    }, [onEditCallback, shipId, keysString]);

    const onDelete = useCallback(() => {
        onDeleteCallback?.(shipId);
    }, [shipId, onDeleteCallback])

    return (
        <tr key={shipId}>
            <td className="col-3">{shipId}</td>
            <td className="col-5">{keysString}</td>
            <td className="col-2 text-center"><button className="btn btn-sm btn-primary px-3" onClick={onEdit}>Edit</button></td>
            <td className="col-2 text-center">
                <DialogBox
                    yesButtonClicked={onDelete}
                    triggerButtonText="Delete"
                    yesButtonClassNames="btn-danger"
                    triggerButtonClassNames="btn-danger btn-sm btn-edit fit-content"
                    dialogDescription="Do you want to delete this entry?"
                    dialogTitle="Delete"
                    yesButtonText="Delete"
                    noButtonText="Cancel"
                />
            </td>
        </tr>
    )
}

export default DataSetRow;

