import { useCallback } from "react";
import { uploadFile } from "../../util/util";

const Uploader = (
  {
    isButtonsDisabled,
    uploadClickedCallback,
    dataUploadedCallback,
    uploadStatusChangedCallback,
    onErrorCallback,
    classNames
  }) => {


  const StatusChanged = useCallback((status) => {
    uploadStatusChangedCallback?.(status);
  },[uploadStatusChangedCallback]);

  const handleUpload = useCallback(async (e) => {

    // if (window.self === window.top && window.showOpenFilePicker) {
    //   readFile(onLoad);
    // } else {
    //   uploadFile(onLoad);
    // }
    uploadClickedCallback?.();
    uploadFile(dataUploadedCallback, StatusChanged, onErrorCallback);
  }, [dataUploadedCallback, StatusChanged, onErrorCallback, uploadClickedCallback]);


  return (
    <button
      type="button"
      disabled={isButtonsDisabled}
      className={`btn btn-primary ${classNames}`}
      onClick={handleUpload}
    >
      Upload
    </button>
  );
}

export default Uploader;