import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog'
import { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import DecimalField from './DecimalField';
import { convertStringToHumanReadable } from '../../util/util';
import _, { update } from 'lodash';
import Loading from '../Loading/Loading';

const TagMetaForm = ({ data: { tagName, ...tagContent }, isOpen, onSaveAsync, onSave, close, ...rest }) => {
    const [content, setContent] = useState(_.cloneDeep(tagContent));
    const [isUpdated, setIsUpdated] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    const handleOnSave = useCallback(async () => {
        if (onSave) {
            onSave({ tagName, ...content });
        } else if (onSaveAsync) {
            setIsWaiting(true);
            await onSaveAsync({ tagName, ...content });
            setIsWaiting(false);
        }

        close();
    }, [close, content, tagName, onSave, onSaveAsync])

    const handleEdit = useCallback((e) => {
        const { name, value } = e.target;
        content[name] = value || null;

        if (name === 'decimals') {
            setContent(_.cloneDeep(content));
        }

        setIsUpdated(!_.isEqual(content, tagContent))
    }, [content, tagContent]);

    return <Dialog fullScreen open={isOpen} {...rest}>
        <DialogTitle>Edit Tag Meta</DialogTitle>
        <DialogContent className='d-flex align-items-center justify-content-center'>
            <div className='container justify-content-center align-items-center'>
                <div className='row justify-content-center'>
                    <TextField className='col-3 mx-4 my-3' label="Tag Name" name={"tagName"} variant="standard" defaultValue={tagName} InputProps={{
                        readOnly: true,
                    }} />
                    {
                        Object.entries(content).map(([key, val]) =>
                            key === "decimals" ?
                                <div key={key} className='col-3 mx-4'>
                                    <label>Decimals</label>
                                    <DecimalField className={"mx-4 my-2"} tagName={tagName} name={key} value={val} editable={true} onSave={handleEdit} />
                                </div>
                                : <TextField className='col-3 mx-4 my-3' label={convertStringToHumanReadable(key)} name={key} onChange={handleEdit} variant="standard" key={key} defaultValue={val} />
                        )
                    }
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={close} disabled={isWaiting}>Cancel</Button>
            <Button onClick={handleOnSave} disabled={!isUpdated || isWaiting}>
                <Loading
                    isLoading={isWaiting}
                    animationOnly={true}
                    animationWidth={"28px"}
                    animationHeight={"28px"}>
                    Save
                </Loading>
            </Button>
        </DialogActions>
    </Dialog>
}

export default TagMetaForm;