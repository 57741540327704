import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { sliceStatus } from "../util/Consts";
import InstanceManager from "../auth/auth";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const fetchDNVRecord = createAsyncThunk(
    "dnv/fetchData",
    async (shipId, { getState }) => {
        try {
            const { dnvHeaders } = getState().dnv;
            if (dnvHeaders.hasOwnProperty(shipId)) {
                return { shipId, undefined };
            }

            const { status, data, statusText } = await InstanceManager.getInstance().get(`/dnv/IMO${shipId}`); 
            if ((status >= 200 && status < 300)) {
                return { shipId, data };
            }
            else NotificationManager.error(statusText);
        } catch (error) {
            if (error?.response?.status === 404){
            return;
            }
            NotificationManager.error("Something went wrong");
        }
    }
);

const deleteDNVChannel = createAsyncThunk(
    "dnv/deleteDNVChannel",
    async (localID, { getState }) => {
        try {
            const { selectedShip, dataChannels: currentDataChannels } = getState().dnv;

            const { status, data, statusText } = await InstanceManager.getInstance().delete(`/dnv/IMO${selectedShip}/${localID}`); 
            if ((status >= 200 && status < 300)) {
               
                return { selectedShip, localID,currentDataChannels };
            }
            else NotificationManager.error(statusText);
        } catch (error) {
            if (error?.response?.status === 404)
                // return null;

            NotificationManager.error("Something went wrong");
        }
    }
);

const updateDNVRecord = createAsyncThunk(
    "dnv/updateData",
    async ({ updatedChannelData }, { getState }) => {

        const { selectedShip, dataChannels: currentDataChannels } = getState().dnv;
        try {
            const { status, statusText } = await InstanceManager.getInstance().post(`/dnv/update/IMO${selectedShip}`, updatedChannelData);
            if (status >= 200 && status < 300) {
            
                return {
                    updatedChannelData, currentDataChannels, selectedShip
                };
            }
            else NotificationManager.error(statusText);
        } catch (error) {
            NotificationManager.error("Something went wrong");
            console.log(error);
        }
    }
);

const insertDNVDataChannel = createAsyncThunk(
    "dnv/insertChannel",
    async ({ updatedChannelData }, { getState }) => {

        const { selectedShip, dataChannels: currentDataChannels } = getState().dnv;
  
        try {
            const { status, statusText } = await InstanceManager.getInstance().post(`/dnv/insert/IMO${selectedShip}`, updatedChannelData);
            if (status >= 200 && status < 300) {
                return {
                    updatedChannelData, currentDataChannels, selectedShip
                };
            }
            else NotificationManager.error(statusText);
        } catch (error) {
            NotificationManager.error("Something went wrong");
            console.log(error);
        }
    }
);

const insertDNVRecord = createAsyncThunk(
    "dnv/insertDNVRecord",
    async ({shipImo,shipName}) => {
       
        try {
            const { status,data, statusText } = await InstanceManager.getInstance().post(`/dnv/create`,{name:shipName,imo:shipImo});
            if (status >= 200 && status < 300) {
                return {
                   shipId:shipImo,
                   data:data
                };
            }
            else NotificationManager.error(statusText);
        } catch (error) {
            NotificationManager.error("Something went wrong");
            console.log(error);
        }
    }
);


const initialState = {
    dnvHeaders: {},
    dataChannels: {},
    availableTags: {},
    selectedShip: null,
    dnvRecordsStatus: "idle",
    dnvUpdateStatus: "idle"
};

const dnvSlice = createSlice({
    name: "dnv",
    initialState,
    reducers: {
        selectShip: (state, action) => {
            state.selectedShip = action.payload;
        },
        setAvailableTags: (state, action) => {
            state.availableTags = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateDNVRecord.pending, (state) => {
                state.dnvUpdateStatus = sliceStatus.LOADING;
            })
            .addCase(updateDNVRecord.rejected, (state) => {
                state.dnvUpdateStatus = sliceStatus.FAILED;
            })
            .addCase(deleteDNVChannel.fulfilled,(state,{payload:{selectedShip, localID,currentDataChannels}})=>{
               const newChannels= _.cloneDeep(currentDataChannels)
                delete newChannels[selectedShip][localID];
                state.dataChannels=newChannels;
            })
            .addMatcher(isAnyOf(fetchDNVRecord.pending,insertDNVRecord.pending), (state) => {
                state.dnvRecordsStatus = sliceStatus.LOADING;
            })
            .addMatcher(isAnyOf(fetchDNVRecord.rejected,insertDNVRecord.rejected), (state) => {
                state.dnvRecordsStatus = sliceStatus.FAILED;
            })
            .addMatcher( isAnyOf(fetchDNVRecord.fulfilled,insertDNVRecord.fulfilled), (state, { payload }) => {  
                if (payload?.shipId && payload?.data) {
                    state.dnvHeaders[payload.shipId] = payload.data.package.header;
                    state.dataChannels[payload.shipId] = payload.data?.package?.dataChannelList
                        ?.dataChannel.reduce((obj, item) => {
                            obj[item.dataChannelID.localID] = item
                            return obj;
                        }, {});
                }
                state.dnvRecordsStatus = sliceStatus.SUCCEEDED;
            })
            .addMatcher(isAnyOf(updateDNVRecord.fulfilled, insertDNVDataChannel.fulfilled), (state, { payload: { updatedChannelData, currentDataChannels, selectedShip } }) => {

                state.dataChannels = {
                    ...currentDataChannels,
                    [selectedShip]: {
                        ...currentDataChannels[selectedShip],
                        [updatedChannelData.dataChannelID.localID]: { ...updatedChannelData }
                    }
                };
                state.dnvUpdateStatus = sliceStatus.SUCCEEDED;
            });
    }
});

export { fetchDNVRecord, updateDNVRecord, insertDNVDataChannel,insertDNVRecord,deleteDNVChannel  };
export const { selectShip, setAvailableTags } = dnvSlice.actions;

export default dnvSlice.reducer;
