import { useCallback, useState } from "react";

const Goto = ({ navigate, current, length }) => {
    const [gotoPage, setGotoPage] = useState(undefined);

    const handleInputChange = useCallback((e) => {
        setGotoPage(e.target.value);
    }, []);

    const handleButtonClick = useCallback(() => {
        const page = parseInt(gotoPage);

        if (!page || page === current || page < 1 || page > length) {
            return;
        }

        navigate(page);
        setGotoPage("");
    }, [current, gotoPage, length, navigate]);

    return (
        <div className="input-group px-2 justify-content-center">
            <input
                type="number"
                className="form-control"
                placeholder="Page"
                aria-label="Page"
                aria-describedby="btn-go"
                min={1}
                max={length}
                value={gotoPage}
                onChange={handleInputChange}
                style={{ width: "180px", maxWidth: "180px" }}
            />
            <button
                className="btn btn-primary input-group-text"
                id="btn-go"
                disabled={!gotoPage || gotoPage === current}
                onClick={handleButtonClick}
            >
                Go
            </button>
        </div>
    );
}
export default Goto;