import { useEffect, useState } from "react";

const Loading = ({
  className,
  circleColor = "#000",
  animationHeight,
  animationWidth,
  scaleAnimation,
  duration = "1s",
  animation = (
    <svg
      className="mx-1"
      viewBox="0 0 38 38"
      stroke={circleColor}
      style={{
        width: animationWidth??"20px",
        height: animationHeight??"20px",
        scale: scaleAnimation,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur={duration}
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  ),
  text = "Loading...",
  textOnly,
  animationOnly,
  textColor = "#000",
  custom,
  isLoading,
  children,
  style,
}) => {
  return isLoading ? (
    <div className={className || ""} style={style || {}}>
      {custom ? (
        { custom }
      ) : (
        <>
          {!textOnly && animation}
          {!animationOnly && (
            <span className="m-2" style={{ color: textColor }}>
              {text}
            </span>
          )}
        </>
      )}
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loading;
