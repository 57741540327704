import { ROLES } from "./auth/RoleManager";
import RequireAuthorization from "./components/Authorization/RequireAuthorization";
import { ShipDataTable } from "./pages/ShipDataTablePage";
import ManageTagMetaPage from "./pages/ManageTagMeta";
import Register from "./pages/register";
import Users from "./pages/Users";
import { ShipDataMapPage } from "./pages/ShipDataMapPage";
import ManageDataSets from "./pages/ManageDataSets";
import Settings from "./pages/Settings";
import ManageDNV from "./pages/ManageDNV";
import ManageShipMetaDataPage from "./pages/ManageShipMetaData";

const AppRoutes = [
  {
    path: "/ship-data-table",
    element: <ShipDataTable />,
  },
  {
    path: "/ship-data-map",
    element: <ShipDataMapPage />,
  },

  {
    path: "/register",
    element: (
      <RequireAuthorization
        roles={[ROLES.ADMIN.name, ROLES.SUPERUSER.name]}
        withInfo={true}
      >
        <Register />
      </RequireAuthorization>
    ),
  },
  {
    path: "/update-tag-meta",
    element: <ManageTagMetaPage />,
  },
  {
    path: "/users",
    element: (
      <RequireAuthorization
        roles={[ROLES.ADMIN.name, ROLES.SUPERUSER.name]}
        withInfo={true}
      >
        <Users />
      </RequireAuthorization>
    ),
  },
  {
    path: "/data-sets",
    element: (
      <RequireAuthorization
        roles={[ROLES.SUPERUSER.name, ROLES.ADMIN.name, ROLES.AUTHOR.name]}
        withInfo={true}
      >
        <ManageDataSets />
      </RequireAuthorization>
    ),
  },
  {
    path: "/dnv",
    element: <ManageDNV />
  },
  {
    path: "/settings",
    element: <Settings />
  },
  {
    path: "ship-metadata",
    element: <ManageShipMetaDataPage />
  }
];

export default AppRoutes;
