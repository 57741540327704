import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import Loading from "../components/Loading/Loading";
import { sliceStatus } from "../util/Consts";
import { fetchAllTags } from "../slices/TagNamesSlice";
import { useCallback } from "react";
import Pagination from "../components/Pagination/Pagination";
import DataSetManager from "../components/DataSet/DataSetSetManager";
import DataTagForm from "../components/DataSet/DataTagForm";
import { ShipList } from "../components/ShipSelector/ShipSelector";
import { deleteDataTag, updateDataTags } from "../slices/dataTagNamesSlice";
import DataSetRow from "../components/DataSet/DataSetRow";

const ManageDataSets = () => {
  const dispatch = useDispatch();
  const {
    selectedDataSet,
    dataSets: datasetList,
    status } = useSelector((state) => state.dataTags);

  const { status: shipSliceStatus, ships } = useSelector((state) => state.tags);

  const [selectedShip, setSelectedShip] = useState(undefined);
  const [renderingData, setRenderingData] = useState([]);
  const [isFormOpen, setFormOpen] = useState(false);
  const [selectedShipForForm, setSelectedShipForForm] = useState(null);
  const [keysStringForForm, setKeyStringForForm] = useState(null);

  const dataTags = useMemo(() => {
    return datasetList[selectedDataSet]?.dataTagList;
  }, [datasetList, selectedDataSet]);

  const shipList = useMemo(() => {
    return ships?.filter(ship => !datasetList[selectedDataSet]?.dataTagList?.some(data => data.imo === ship.imo));
  }, [ships, selectedDataSet, datasetList]);

  const handleNewDataTag = useCallback(() => {
    setKeyStringForForm(null);
    setFormOpen(true);
  }, []);

  const onSave = useCallback((shipId, dataset, keys) => {
    const dt = { imo: shipId, tagName: keys.join(".") };
    dispatch(updateDataTags({ selectedDataset: selectedDataSet, updatedDataTag: dt }))
    setFormOpen(false);

  }, [dispatch, selectedDataSet])

  const onEditClicked = useCallback(({ shipId, keysString }) => {
    setSelectedShipForForm(shipId);
    setKeyStringForForm(keysString);
    setFormOpen(true)
  }, [])

  const onDeleteDataTagClicked = useCallback((shipImo) => {

    dispatch(deleteDataTag({ dataset: selectedDataSet, shipImo }))
  }, [dispatch, selectedDataSet])

  const selectedShipChanged = useCallback((ship) => {
    setSelectedShip(ship);
    setSelectedShipForForm(ship);
  }, [])
  const onFormClose = useCallback(() => {
    setFormOpen(false);
  })
  useEffect(() => {
    dispatch(fetchAllTags());
  }, [dispatch]);

  return (
    <div className="container">
      {isFormOpen &&
        <DataTagForm
          isOpen={isFormOpen}
          shipId={selectedShipForForm}
          dataSet={selectedDataSet}
          keysString={keysStringForForm}
          onCancelCallback={onFormClose}
          onSaveCallback={onSave}
        />}
      <div className="row mb-4 justify-content-center">
        <DataSetManager />
      </div>

      {selectedDataSet &&
        <>
          <div className="row mb-4 justify-content-center">
            <div className="col miw-140-px mb-2 mb-md-0">
              <Loading
                isLoading={shipSliceStatus === sliceStatus.LOADING}
                animationWidth={"30px"}
                animationHeight={"30px"}
              >

                <ShipList
                  shipList={shipList}
                  isDisabled={!selectedDataSet}
                  onShipSelect={selectedShipChanged}
                  selectedShip={selectedShip}
                  placeHolder={"Select a Ship to add a mapping"}
                />
              </Loading>
            </div>

            <button
              disabled={!selectedShip || !selectedDataSet}
              type="btn"
              className="btn btn-primary col-3 miw-140-px"
              onClick={handleNewDataTag}
            >
              Add New Entry &nbsp; <i className="fa-solid fa-calendar-plus"></i>
            </button>
          </div>

          <div className="row">
            <Loading
              className="w-100 d-flex justify-content-center align-items-center"
              isLoading={!dataTags?.name && status === sliceStatus.LOADING}
              animationWidth={"30px"}
              animationHeight={"30px"}
            >
              {status === sliceStatus.FAILED ? (
                <div className="w-100 text-center">Something went wrong</div>
              ) : (
                <>
                  <div className="data-table mw-100 custom-table">
                    <table className="table table-hover table-responsive table-bordered custom-table position-relative">
                      {dataTags?.length > 0 &&
                        <>
                          <thead>
                            <tr>
                              <th>Ship Id</th>
                              <th className="col-6">Data Tag</th>
                              <th>Save</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderingData.map(({ imo, tagName }) => {
                              return (
                                <DataSetRow
                                  key={imo}
                                  onDeleteCallback={onDeleteDataTagClicked}
                                  shipId={imo}
                                  keysString={tagName}
                                  onEditCallback={onEditClicked}
                                />
                              );
                            })}
                          </tbody>
                        </>
                      }
                    </table>
                  </div>
                  <div className="container">
                    <div className="row my-3 justify-content-between align-items-center">
                      {dataTags?.length > 0 && <Pagination
                        className="fit-content"
                        withElementsPerPage={true}
                        withJump={true}
                        data={dataTags || []}
                        setRenderingData={setRenderingData}
                      />}
                    </div>
                  </div>
                </>
              )}
            </Loading>

          </div>
        </>
      }
    </div>
  );
};

export default ManageDataSets;
