import React from 'react';

const Home = () => {
    return (
        <div className='container h-100 d-flex justify-content-center align-items-center min-h-100' style={{ paddingTop: "35vh" }}>
            <div>
                <h1 className='text-white bg-black px-5 py-3 rounded text-center'>Welcome to Torghatten ASA</h1>
            </div>
        </div>
    );
};

export default Home;