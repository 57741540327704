import { useEffect, useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectShip, fetchDNVRecord, setAvailableTags, insertDNVDataChannel, insertDNVRecord } from "../slices/dnvSlice"
import { ShipList } from "../components/ShipSelector/ShipSelector";
import DNVMetaTable from "../components/DNV/DNVMetaTable";
import { sliceStatus } from "../util/Consts";
import { ROLES } from "../auth/RoleManager";
import Loading from "../components/Loading/Loading";
import DNVChannelForm from "../components/DNV/DNVForm";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ManageDNV = () => {
    const dispatch = useDispatch();

    const { selectedShip, dataChannels, dnvHeaders, dnvRecordsStatus: status, dnvUpdateStatus } = useSelector(state => state.dnv);
    const { filteredTagsByShip } = useSelector(state => state.tags);
    const { ships } = useSelector(state => state.tags)
    const { role } = useSelector((state) => state.auth);

    const [modelOpen, setModelOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    const authorized = useMemo(() => {
        return [ROLES.SUPERUSER.name, ROLES.ADMIN.name, ROLES.AUTHOR.name].includes(role);
    }, [role]);

    const tableData = useMemo(() => {
        return Object.values(dataChannels[selectedShip] ?? {});
    }, [dataChannels, selectedShip])

    const noDnvRecord = useMemo(() => {
        return !dnvHeaders.hasOwnProperty(selectedShip);
    }, [dnvHeaders, selectedShip]);

    const clearSelectedShip = useCallback(() => {
        dispatch(selectShip(null));
    }, [dispatch]);

    const onShipSelect = useCallback((shipId) => {
        dispatch(selectShip(shipId));
    }, [dispatch]);

    const isDataLoading = useCallback(() => {
        return status === sliceStatus.LOADING;
    }, [status])

    const isDataUpdating = useCallback(() => {
        return dnvUpdateStatus === sliceStatus.LOADING;
    }, [dnvUpdateStatus])

    const onAddNewDnvMetaClicked = useCallback(() => {
        setModelOpen(true);
    }, []);

    const onAddNewDnvRecordClicked = useCallback(() => {
        const selectedShipObject = ships.find(item => item.imo === selectedShip);
        dispatch(insertDNVRecord({ shipImo: selectedShipObject.imo, shipName: selectedShipObject.name }))
    }, [dispatch, selectedShip, ships])

    const cancelNewSaveDnvMeta = useCallback(() => {
        setModelOpen(!modelOpen);
    }, [modelOpen]);

    const saveNewSaveDnvMeta = useCallback(async (updatedChannelData) => {
        setIsCreating(true);
        await dispatch(insertDNVDataChannel({ updatedChannelData }));
        setIsCreating(false);
        cancelNewSaveDnvMeta();
    }, [dispatch, cancelNewSaveDnvMeta]);

    useEffect(() => {
        if (selectedShip) {
            dispatch(fetchDNVRecord(selectedShip));
        }
    }, [selectedShip, dispatch]);

    useEffect(() => {
        const availableTags = Object.fromEntries(
            Object.entries(filteredTagsByShip[selectedShip]?.tagList ?? {})
                .filter(([key, value]) => {
                    return !tableData.some(item => {
                        return item.dataChannelID.shortID === key;
                    });
                })
        ) ?? {};
        dispatch(setAvailableTags(availableTags))
    }, [dataChannels, selectedShip, filteredTagsByShip]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-11">
                    <ShipList
                        shipList={ships}
                        onShipSelect={onShipSelect}
                        selectedShip={selectedShip}
                        clearFunction={clearSelectedShip}
                        isDisabled={isDataLoading()}
                    ></ShipList>
                </div>

                {selectedShip && authorized &&
                    <div className="col-1">
                        {noDnvRecord ?
                            <Loading
                                isLoading={isDataLoading()}
                                text=""
                            >
                                <button className="btn btn-primary btn-sm py-0 dnvBtn btn-square" onClick={onAddNewDnvRecordClicked} title="Add DNV Record">
                                    <i className="fa-solid fa-calendar-plus"></i>
                                </button>
                            </Loading>
                            :

                            <Loading
                                isLoading={isDataUpdating()}
                                text=""
                            >
                                <button className="btn btn-primary btn-sm py-0 dnvBtn btn-square" onClick={onAddNewDnvMetaClicked} title="Add a new Entry">
                                    <i className="fa-solid fa-file-circle-plus"></i>
                                </button>
                                <Modal isOpen={modelOpen}
                                    toggle={cancelNewSaveDnvMeta}
                                    size="lg">
                                    <ModalHeader
                                        toggle={cancelNewSaveDnvMeta}
                                    >

                                        Add DNVMeta

                                    </ModalHeader>
                                    <ModalBody>
                                        <DNVChannelForm
                                            rowData={{}}
                                            onSave={saveNewSaveDnvMeta}
                                            onCancel={cancelNewSaveDnvMeta}
                                            isNew={true}
                                            isLoading={isCreating}
                                        />
                                    </ModalBody>
                                </Modal>

                            </Loading>
                        }
                    </div>
                }

                <Loading
                    isLoading={isDataLoading()}
                    className={"pt-5 w-100 text-center"}
                >
                    <div className="table-responsive col-12">
                        <DNVMetaTable
                            className="table-component"
                            tableData={tableData}
                            selectedShip={selectedShip}
                            updateStatus={dnvUpdateStatus}
                            authorizedToEdit={authorized}
                        />
                    </div>
                </Loading>
            </div>
        </div>

    );
};

export default ManageDNV
