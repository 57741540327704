import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  data: {},
  openedSections: {}
};

const viewPopUpSlice = createSlice({
  name: "viewPopUp",
  initialState,
  reducers: {
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    editState: (state, { payload: { key, data } }) => {
      if (key)
        state.data = { ...state.data, [key]: data }
    },
    openSection: (state, { payload: { id } }) => {
      state.openedSections = { ...state.openedSections, [id]: true }
    },
    closeSection: (state, { payload: { id } }) => {
      if (state.openedSections[id])
        delete state.openedSections[id];

      state.openedSections = { ...state.openedSections };

      if (Object.keys(state.openedSections).length === 0)
        state.isOpen = false;
    }
  },
});

export const { setIsOpen, editState, openSection, closeSection } = viewPopUpSlice.actions;

export default viewPopUpSlice.reducer;
