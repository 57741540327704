import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sliceStatus } from "../util/Consts";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import InstanceManager from "../auth/auth";


const createOrUpdateDataSet = createAsyncThunk(
  "dataTagNames/createOrUpdateDataSet",
  async ({ content }) => {
    try {
      const { status, statusText } = await InstanceManager.getInstance().post(`/data`, content);

      if (status >= 200 && status < 300) {
        return content;
      } else NotificationManager.error(statusText);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      console.log(error);
    }
  }
);

const updateDataTags = createAsyncThunk(
  "dataTagNames/updateDataTags",
  async ({ selectedDataset, updatedDataTag }, { getState }) => {
    const { dataSets: { [selectedDataset]: editedDataSet } } = getState().dataTags;

    const dataset = { ...editedDataSet }
    if (!dataset.dataTagList) {
      dataset.dataTagList = [];

    }

    const newArr = dataset.dataTagList.filter(item => item.imo !== updatedDataTag.imo);
    newArr.push(updatedDataTag);

    try {
      const { status, statusText } = await InstanceManager.getInstance().post(
        `/data/tagList/${selectedDataset}`,
        newArr
      );

      if (status >= 200 && status < 300) {
        return { dataSet: selectedDataset, dataTagList: newArr };
      } else NotificationManager.error(statusText);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      console.log(error);
    }

  });

const deleteDataTag = createAsyncThunk(
  "dataTagNames/deleteDataTag",
  async ({ dataset, shipImo }) => {
    try {
      const { status, statusText } = await InstanceManager.getInstance()
        .delete(`/data/${dataset}/tag/${shipImo}`);

      if (status >= 200 && status < 300) {
        return { dataset, shipImo };
      } else NotificationManager.error(statusText);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      console.log(error);
    }
  })

const getAllDataSets = createAsyncThunk(
  "dataTagNames/getAllTypes",
  async () => {
    try {
      const { status, data, statusText } = await InstanceManager.getInstance().get("/data/list",
        {
          params: { withTagList: true }
        });

      if (status >= 200 && status < 300) {
        return data;
      } else NotificationManager.error(statusText);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      console.log(error);
    }
  }
);

const deleteDataSet = createAsyncThunk("dataTagNames/deleteDataSet",
  async ({ dataSet }, { dispatch, getState }) => {
    try {
      const { status, statusText } = await InstanceManager.getInstance().delete("/data/" + dataSet);

      if (status >= 200 && status < 300) {
        dispatch(setSelectedDataTag(null));

        const dataSets = {};
        Object.values(getState().dataTags.dataSets || {}).forEach(dataSetObject => {
          if (dataSetObject.name !== dataSet)
            dataSets[dataSetObject.name] = dataSetObject;
        });
        return dataSets;
      } else NotificationManager.error(statusText);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      console.log(error);
    }
  }
)

const initialState = {
  selectedDataSet: null,
  dataSets: {},
  dataTags: {},
  shipsWithoutDataSet: [],
  status: "idle",
  dataSetStatus: "idle"
};

const dataTagNamesSlice = createSlice({
  name: "dataTagNames",
  initialState,
  reducers: {
    setSelectedDataTag: (state, { payload }) => {
      state.selectedDataSet = payload;
    },
    clearTags: (state) => {
      state.dataTags = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDataSets.pending, (state, action) => {
        state.dataSetStatus = sliceStatus.LOADING;
      })
      .addCase(getAllDataSets.rejected, (state, action) => {
        state.dataSetStatus = sliceStatus.FAILED;
      })
      .addCase(getAllDataSets.fulfilled, (state, { payload }) => {
        const dataSets = {};
        payload.forEach((dataSet) => {
          dataSets[dataSet?.name] = dataSet;
        });

        state.dataSets = dataSets;
        state.dataSetStatus = sliceStatus.SUCCEEDED;
      })
      .addCase(updateDataTags.pending, (state, action) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(updateDataTags.rejected, (state, action) => {
        state.status = sliceStatus.FAILED;
      })
      .addCase(updateDataTags.fulfilled, (state, { payload: { dataSet, dataTagList } }) => {
        const newDataSets = { ...state.dataSets };
        newDataSets[dataSet].dataTagList = dataTagList;
        state.dataSets = { ...newDataSets }
        state.status = sliceStatus.SUCCEEDED;
      })
      .addCase(deleteDataTag.fulfilled, (state, { payload: { dataset: datasetName, shipImo } }) => {
        const dataset = { ...state.dataSets[datasetName] };
        dataset.dataTagList = dataset?.dataTagList.filter(dataTag => dataTag.imo !== shipImo);
        state.dataSets = { ...state.dataSets, [datasetName]: dataset }
      })
      .addCase(createOrUpdateDataSet.pending, (state, action) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(createOrUpdateDataSet.rejected, (state, action) => {
        state.status = sliceStatus.FAILED;
      })
      .addCase(createOrUpdateDataSet.fulfilled, (state, { payload }) => {
        if (payload) {
          const dataSets = _.cloneDeep(state.dataSets);
          dataSets[payload.name] = payload;

          state.dataSets = dataSets;
        }
        state.status = sliceStatus.SUCCEEDED;
      })
      .addCase(deleteDataSet.pending, (state) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(deleteDataSet.fulfilled, (state, { payload }) => {
        state.dataSets = _.cloneDeep(payload);

        state.status = sliceStatus.SUCCEEDED;
      })
      .addCase(deleteDataSet.rejected, (state) => {
        state.status = sliceStatus.FAILED;
      });
  },
});

export {
  getAllDataSets,
  updateDataTags,
  createOrUpdateDataSet,
  deleteDataSet,
  deleteDataTag
};

export const { setSelectedDataTag, clearTags } = dataTagNamesSlice.actions;

export default dataTagNamesSlice.reducer;
