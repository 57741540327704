import React, { useCallback, useState } from 'react';
import { dataChannelDescriptions } from "./dnvConst.js";

const EditLocalID = ({ parts, onChange,isDisabled }) => {
    const [item, setItem] = useState(parts.primary_item??"");
    const [quantity, setQuantity] = useState(parts.quantity??"");

    const handleItemChange = useCallback((e) => {
        setItem(e.target.value)
        const localID = `/dnv-v2/vis-3-6a/${e.target.value}/meta/${quantity}`
        onChange(localID);
    }, [quantity, onChange]);
    const handlequantityChange = useCallback((e) => {
        setQuantity(e.target.value)
        const localID = `/dnv-v2/vis-3-6a/${item}/meta/${e.target.value}`
        onChange(localID);
    }, [item, onChange]);

    return (
        <div className="mb-1">
            <span className="px-3 py-1">{dataChannelDescriptions.localID} *</span>
            <div>
            <label className="px-0 ps-3 py-1 col-3 ">
            /dnv-v2/vis-3-6a/
            </label>
            <label className="px-1 py-1 col-3">
                <input className="form-control text=center"
                    type="text"
                    value={item}
                    placeholder='Primary Item'
                    onChange={handleItemChange}
                    disabled={isDisabled}
                    required
                />
            </label>
            <label className="px-1 py-1 col-2 text-center">
            /meta/
            </label>
            <label className="px-1 pe-3 py-1 col-4">
                <input className="form-control"
                    type="text"
                    value={quantity}
                    placeholder='Quantity'
                    onChange={handlequantityChange}
                    disabled={isDisabled}
                    required
                />
            </label>
            </div>
            <hr/>
        </div>
    );

};

export default EditLocalID;