import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAuth = ({ element, redirectTo }) => {
    const token = useSelector(state => state.auth.token);
    const location = useLocation();
    return (
        <>
            {token ? (
                element
            ) : (
                    <Navigate replace to={redirectTo} state={{ redirectTo: location }} />
            )}
        </>
    );
};

export default RequireAuth;