import React, { useCallback } from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import Footer from "./Footer";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ViewPopUp from "./Popup/ViewPopUp";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpen } from "../slices/viewPopUpSlice";

export const Layout = ({children}) => {
  const dispatch = useDispatch();
  const { isOpen: isPopupOpen, openedSections } = useSelector(state => state.viewPopUp);
  
  const openPopUp = useCallback(() => {
    dispatch(setIsOpen(true));
  }, [dispatch]);
  
  return (
    <div>
      <NavMenu />
      <Container className={`position-relative flex-row d-flex ${isPopupOpen && "ps-0"}`} tag="main" fluid style={{ maxWidth: "100vw" }}>
        <NotificationContainer />
        <ViewPopUp width={50} />
        {children}

        {!isPopupOpen && Object.entries(openedSections).length > 0 && <button onClick={openPopUp} className="btn btn-secondary position-absolute start-0 popup-open-btn-left">
          <i className="fa fa-angle-right"></i>
        </button>}
      </Container>
      <Footer />
    </div>
  );
}

export default Layout;