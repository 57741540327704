import { useSelector } from "react-redux";
import ChangePassword from "../components/Password/changePassword"; 
import { ROLES } from "../auth/RoleManager";

const Settings = () => {
  const { email, role } = useSelector((state) => state.auth);

  return (
    <div className="container">
      <h1 className="text-center my-4">Profile Settings</h1>
      <div className="row d-flex align-items-center justify-content-center mb-3">
        <div className="col col-md-7 miw-140-px">
          <div className="input-group">
            <span className="input-group-text">Email</span>
            <input
              className="form-control"
              id="email"
              type="email"
              disabled
              value={email}
            />
          </div>
        </div>
      </div>
      {role !== ROLES.SUPERUSER.name && <div className="row d-flex align-items-center justify-content-center mb-3">
        <div className="col col-md-7 miw-140-px">
            <ChangePassword />
        </div>
      </div>}
    </div>
  );
};

export default Settings;
