import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getShipDataObjectByTagname } from '../../slices/shipDataSlice';
import InputWithSuggestions from '../inputWithSuggestions/inputWithSuggestions';

const DecimalField = ({ tagName, className, name, value, editable, onSave }) => {
    const dispatch = useDispatch();

    const { selectedShipId } = useSelector((state) => state.tags);

    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(_.cloneDeep(value));
    const [isLoading, setLoading] = useState(false);
    const [dataObject, setDataObject] = useState({});
    const [fieldNames, setFieldNames] = useState([]);

    const handleToggle = useCallback(() => {
        setIsOpen(state => !state);
    }, [setIsOpen]);

    const handleOnChange = useCallback((e) => {
        const { name: eName, value: eValue } = e.target;
        data[eName] = Number.parseInt(eValue);
    }, [data]);

    const handleOnSave = useCallback(() => {
        onSave({ target: { name, value: data } });
        handleToggle();
    }, [onSave, handleToggle, name, data]);

    const handleRemoveField = useCallback((e) => {
        const { name: eName } = e.target;
        if (data[eName]) {
            delete data[eName];

            setData(_.cloneDeep(data));
        }
    }, [data]);

    const handleAddField = useCallback((fieldName) => {
        if (fieldName) {
            setData({ [fieldName]: -1, ...data })
        }
    }, [data]);

    const handleOnClose = useCallback(() => {
        setData(_.cloneDeep(value));
        setDataObject({});
    }, [value])

    const handleOnOpen = useCallback(() => {
        setData(_.cloneDeep(value));
        dispatch(getShipDataObjectByTagname({ shipId: selectedShipId, tagName, setLoading })).then(({ payload }) => {
            setDataObject(payload);
        })
    }, [selectedShipId, tagName, setLoading, dispatch, value]);

    useEffect(() => {
        const { data: fieldData } = dataObject || {}, fields = [];

        Object.keys(fieldData || {}).forEach(name => {
            if (!data?.[name]) {
                fields?.push({ name });
            }
        });
        setFieldNames(fields)
    }, [dataObject, data]);

    return <>
        <div className={className}>
            <button className="btn btn-primary" type="btn" onClick={handleToggle}>
                {editable ? "Edit" : "View"}
            </button>
            <Modal isOpen={isOpen} toggle={handleToggle} onClosed={handleOnClose} onOpened={handleOnOpen}>
                <ModalHeader>
                    Decimals
                </ModalHeader>
                <ModalBody>
                    <div className="container py-2" style={{ maxHeight: "80vh", over0flow: "auto" }}>
                        {editable && <div className="row">
                            <div className="col d-flex justify-content-end">
                                <InputWithSuggestions
                                    clearAfterSelection={true}
                                    isDisabled={isLoading}
                                    placeHolder={"Select Field"}
                                    selectedItem={null}
                                    onSelectSuggestion={handleAddField}
                                    selectedDataList={fieldNames}
                                    itemKeyField={"name"}
                                    itemDescField={"desc"}
                                />
                            </div>
                        </div>}
                        {
                            Object.entries(data || {}).map(([key, val]) => <div key={key} className="input-group mt-3">
                                <span name={key} className="input-group-text">{key}</span>
                                <input className="form-control" type="number" min={-1} name={key} defaultValue={val || -1} disabled={!editable} onChange={handleOnChange}></input>
                                {editable && <button className='btn btn-secondary' name={key} type='btn' onClick={handleRemoveField}>
                                    <i className='fa fa-trash'></i>
                                </button>}
                            </div>)
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {editable && <button className='btn btn-primary' type='btn' onClick={handleOnSave}>Save</button>}
                    <button className='btn btn-primary' type='btn' onClick={handleToggle}>{editable ? "Cancel" : "Close"}</button>
                </ModalFooter>
            </Modal>
        </div>
    </>

}

export default DecimalField;