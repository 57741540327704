import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sliceStatus } from "../util/Consts";
import InstanceManager from "../auth/auth";

const requestDataset = async (shipId, dataset, startTime, endTime) => {
  const { data } = await InstanceManager.getInstance().get(`/data/${shipId}/${dataset}`, {
    params: {
      from: startTime ? startTime : null,
      to: endTime ? endTime : null,
    },
  });
  return data;
};

const requestPath = async (shipId, startTime, endTime) => {
  const { data } = await InstanceManager.getInstance().get(`/positions/${shipId}`, {
    params: {
      from: startTime ? startTime : null,
      to: endTime ? endTime : null,
    },
  });
  return data;
};

export const fetchpathForShip = createAsyncThunk(
  "dataset/fetchPath",
  async ({ shipId, startTime, endTime }, { getState, rejectWithValue }) => {
    const {
      dataset: { paths },
    } = getState();

    if (paths?.[shipId]) {
      return;
    }
    const positions = await requestPath(shipId, startTime, endTime);

    const path = positions.map((item) => {
      return item.position;
    });
    return { data: path, shipId: shipId };
  }
);

export const fetchDataSet = createAsyncThunk(
  "dataset/fetchdata",
  async ({ shipId, dataset, startTime, endTime }, { getState }) => {
    const {
      dataset: { data: currentData },
    } = getState();
    if (currentData?.[shipId]?.[dataset]) {
      return;
    }
    const data = await requestDataset(shipId, dataset, startTime, endTime);

    return { data: data, shipId: shipId, dataset: dataset };
  }
);

const initialState = {
  data: {},
  timeRange: {
    startTime: null,
    endTime: null,
  },
  paths: {},
  selectedShip: null,
  selectedDataset: null,
  status: "idle",
  error: null,
};

const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    selectShip: (state, action) => {
      state.selectedShip = action.payload;
    },
    selectDataSet: (state, action) => {
      state.selectedDataset = action.payload;
    },
    setShipData: (state, action) => {
      const messages = action.payload[0];
      const currentTagName = action.payload[1];
      if (messages["shipData"].length > 0) {
        state.messageData[currentTagName] = messages["shipData"];
        state.tagMetadata[currentTagName] = messages["tagMetaData"];
      } else {
        state.messageData[currentTagName] = [];
        state.tagMetadata[currentTagName] = {};
      }
    },
    setMap: (state, action) => {
      state.map = action.payload;
    },
    clearData: (state, action) => {
      state.paths = {};
      state.data = {};
    },
    setTimeRange: (state, action) => {
      state.timeRange = action.payload;
    },
    reset: (state, action) => {
      state.endTime = null;
      state.startTime = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchpathForShip.pending, (state, action) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(fetchDataSet.pending, (state, action) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(fetchpathForShip.fulfilled, (state, action) => {
        if (action?.payload) {
          const { shipId, data } = action.payload;
          state.paths[shipId] = data;
        }
        state.status = sliceStatus.SUCCEEDED;
      })
      .addCase(fetchDataSet.fulfilled, (state, action) => {
        if (action?.payload) {
          const { shipId, dataset, data: response } = action.payload;
          const newData = { ...state.data };
          if (!newData[shipId]) {
            newData[shipId] = {};
          }
          newData[shipId][dataset] = response;
          state.data = newData;
        }
        state.status = sliceStatus.SUCCEEDED;
      });
  },
});

export const { selectShip, selectDataSet, setTimeRange, clearData } =
  datasetSlice.actions;
export default datasetSlice.reducer;
