import { VIEW_POP_UP_ELEMENT_IDS } from "../../util/Consts";
import Sidebar from "../Sidebar/Sidebar";
import ViewPopUpWrapper from "./ViewPopUpWrapper";

const ViewPopUpContent = () => {
    return <>
        <ViewPopUpWrapper path={VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA}>
          <Sidebar />
        </ViewPopUpWrapper>
    </>;
}

export default ViewPopUpContent;