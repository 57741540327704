import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputWithSuggestions from "../inputWithSuggestions/inputWithSuggestions";
import { fetchAllTags, selectShip } from "../../slices/TagNamesSlice";
import Loading from "../Loading/Loading";
import { sliceStatus } from "../../util/Consts";
import { ShipMeta } from "../ShipMeta/ShipMeta";

export function ShipList({ shipList, onShipSelect, selectedShip, clearFunction, withoutMetaData, isDisabled,placeHolder }) {

  const dispatch = useDispatch();
  const { status, error, selectedShip: defaultSelectedShip } = useSelector(state => state.tags);

  const handleShipSelect = useCallback((ship) => {
    if (onShipSelect) {
      onShipSelect(ship);
    } else {
      dispatch(selectShip(ship));
    }
  }, [dispatch, onShipSelect]);

  useEffect(() => {
    if (!shipList) {
      dispatch(fetchAllTags());
    }
  }, [dispatch, shipList]);

  const selectedItem = useMemo(() => {
    return selectedShip === undefined ? defaultSelectedShip : selectedShip;
  }, [selectedShip, defaultSelectedShip]);

  return (
    <Loading
      className={"d-flex align-items-center"}
      isLoading={status === sliceStatus.LOADING}
      animationWidth={"28px"}
      animationHeight={"28px"}
    >
      {status === sliceStatus.SUCCEEDED ? (
        <div className="d-flex align-items-center">
          <InputWithSuggestions
            selectedDataList={shipList}
            onSelectSuggestion={handleShipSelect}
            selectedItem={selectedItem}
            placeHolder={placeHolder??"Select a ship"}
            itemKeyField="imo"
            itemDescField="name"
            clearFunction={clearFunction}
            isDisabled={isDisabled}
          />

          {!withoutMetaData && selectedItem && (<ShipMeta selectedItem={selectedItem} />)}
        </div>
      ) : (
        <div>{error}</div>
      )}
    </Loading>
  );
} 