import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const DataSetForm = ({ dataset, isOpen, onSaveCallback, onCancelCallback,onClosedCallback }) => {
    const [editingData, setEditingData] = useState(null);

    const isEdit = useMemo(() => {
        return dataset;
    }, [dataset]);

    const isDisabled = useMemo(() => {
        return !editingData?.name;
    }, [editingData]);

    const handleOnChange = useCallback((e) => {
        setEditingData({ ...editingData, [e.target.name]: e.target.value });
    }, [editingData]);

    const onSaveClicked = useCallback(() => {
        onSaveCallback?.(editingData);
    }, [editingData, onSaveCallback]);

    const onCancelCliced=useCallback(()=>{
        onCancelCallback?.();
    },[onCancelCallback])

    const onClose=useCallback(()=>{
        setEditingData(null);
        onClosedCallback?.();
    },[onClosedCallback]);

    useEffect(() => {
        if (dataset) {
            setEditingData(_.cloneDeep(dataset));
        } else {
            setEditingData({});
        }
    }, [dataset]);


    return (
        <Modal isOpen={isOpen} onClosed={onClose} centered={true} >
            <ModalHeader>{isEdit ? "Edit" : "Create"} DataSet</ModalHeader>
            <ModalBody>
                <input
                    className="form-control col mb-2"
                    type="text"
                    name="name"
                    onChange={handleOnChange}
                    value={editingData?.name??""}
                    placeholder="Data Set Name"
                />
                <input
                    className="form-control col my-2 mb-md-0"
                    type="text"
                    value={editingData?.description??""}
                    placeholder="Description"
                    onChange={handleOnChange}
                    name="description"
                />
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end">
                    <button
                        className="btn btn-primary me-1"
                        disabled={isDisabled}
                        onClick={onSaveClicked}> Save</button>
                    <button
                        className="btn btn-secondary"
                        onClick={onCancelCliced}> Cancel</button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default DataSetForm;