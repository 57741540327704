import React, { useCallback, useEffect, useState } from 'react';
import './DNV.css';
import DNVChannelForm from './DNVForm';
import _ from 'lodash';
import { dataChannelKeys } from "./dnvConst.js";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loading from '../Loading/Loading';
import { sliceStatus } from '../../util/Consts';
import DialogBox from '../DialogComponent/Dialog';
import { useDispatch } from 'react-redux';
import { deleteDNVChannel } from '../../slices/dnvSlice';


const DNVRow = ({ rowData, onUpdate, authorizedToEdit, uploadingStatus }) => {
    const dispatch=useDispatch();
    const [modal, setModal] = useState(false);
    const toggle = useCallback(() => setModal(!modal), [modal]);
    const [updateStarted, setUpdatingStarted] = useState(false);

    const handleSave = useCallback((editedData) => {
        setUpdatingStarted(true);
        onUpdate(editedData);
        toggle();
    }, [onUpdate, toggle]);

    useEffect(() => {
        if (updateStarted && uploadingStatus !== sliceStatus.LOADING)
            setUpdatingStarted(false);
    }, [updateStarted, uploadingStatus])

    const isUpdating = useCallback(() => {
        if (updateStarted && uploadingStatus === sliceStatus.LOADING)
            return true;
        return false;
    }, [updateStarted, uploadingStatus])

    const onDeleteClicked = useCallback(() => {
        dispatch(deleteDNVChannel(_.get(rowData,dataChannelKeys.localID)))
    },[dispatch,rowData]);

    const handleCancel = useCallback(() => {
        toggle();
    }, [toggle]);

    return (
        <>

            <tr className='dnv-row'>

                {Object.keys(dataChannelKeys).map((key) => (
                    <td key={key} className='align-middle text-center dnv-cell col-auto'>
                        {_.get(rowData, dataChannelKeys[key], 'N/A') || "N/A"}
                    </td>
                ))}
                {authorizedToEdit && (
                    <td className='controls align-middle text-center col-auto mx-auto'>

                        <button
                            className='btn btn-primary btn-edit fit-content me-2'
                            onClick={toggle}>
                            <Loading
                                isLoading={isUpdating()}
                                text='Updating ...'
                            >
                                Edit
                            </Loading>
                        </button>
                        <DialogBox
                            yesButtonClicked={onDeleteClicked}
                            triggerButtonText="Delete"
                            yesButtonClassNames="btn-danger"
                            triggerButtonClassNames="btn-danger btn-edit fit-content"
                            dialogDescription="Do you want to delete this Channel?"
                            dialogTitle="Delete"
                            yesButtonText="Delete"
                            noButtonText="Cancel"
                        />

                    </td>
                )}
            </tr>
            {authorizedToEdit && (
                <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} >

                        Edit DNVMeta

                    </ModalHeader>
                    <ModalBody>
                        <DNVChannelForm
                            rowData={rowData}
                            onSave={handleSave}
                            onCancel={handleCancel}
                        />
                    </ModalBody>
                </Modal>)}

        </>);
};

export default DNVRow;
