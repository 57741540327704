import Goto from "./Goto";
import ElementsPerPage from "./ElementsPerPage";
import PaginationButtons from "./PaginationButtons";
import { useEffect, useState } from "react";
import { getTotalPageCount, sliceData } from "../../util/util";

const Pagination = ({
  className,
  withElementsPerPage,
  withJump,
  data = [],
  setRenderingData,
  elementsPerPageOptional,
  setElementsPerPageOptional,
}) => {

  const [page, setPage] = useState(1);

  const [elementsPerPage, setElementsPerPage] = useState(
    elementsPerPageOptional || 15
  );

  const [length, setLength] = useState(
    data
      ? getTotalPageCount(data, elementsPerPageOptional || elementsPerPage)
      : 0
  );

  useEffect(() => {
    if (data)
      setLength(
        getTotalPageCount(data, elementsPerPageOptional || elementsPerPage)
      );
  }, [data, elementsPerPage, elementsPerPageOptional]);

  useEffect(() => {
    const [slicedData, totalPageCount] = sliceData(
      data,
      page,
      elementsPerPageOptional || elementsPerPage,
      null,
      true
    );

    setRenderingData(slicedData);
    setLength(totalPageCount);
  }, [page, elementsPerPage, elementsPerPageOptional, data, setRenderingData]);

  return (
    <>
      {withElementsPerPage && (
        <ElementsPerPage
          elementsPerPage={elementsPerPageOptional || elementsPerPage}
          setElementsPerPage={setElementsPerPageOptional || setElementsPerPage}
        />
      )}

      <div
        className={`d-flex flex-column mt-3 flex-sm-row w-sm-auto w-100 m-auto mt-2 mt-lg-0 
        ${withElementsPerPage && "m-lg-0"}
        `}
      >
        <ul
          className={`pagination d-flex m-auto justify-content-center align-items-center mb-2 mb-sm-0
           ${className}
            ${withElementsPerPage && " m-sm-0"}
          `}
        >
          <PaginationButtons
            current={page}
            length={length}
            navigate={setPage}
          />
        </ul>
        {withJump && <Goto navigate={setPage} length={length} current={page} />}
      </div>
    </>
  );
};

export default Pagination;
