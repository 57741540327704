import { useCallback, useMemo } from "react";

const PaginationButtons = ({ length, current, navigate }) => {

    const pages = useMemo(() => {
        if (length === 0) {
            return [];
        }

        const pageRange = 1;
        let start = Math.max(1, current - pageRange);
        let end = Math.min(length, current + pageRange);
        const pagesArray = [];

        for (let i = start; i <= end; i++) {
            pagesArray.push({ page: i, active: i === current });
        }

        return pagesArray;
    }, [length, current]);

    const onPageclick = useCallback((event) => {
        navigate(parseInt(event.target.name));
    }, [navigate]);

    return (
        <>
            <li className={`page-item ${current === 1 && "disabled"}`}>
                <button
                    onClick={onPageclick}
                    name={`${1}`}
                    className="page-link"
                    tabIndex={current === 0 ? "-1" : "0"}
                    aria-disabled={current === 1}
                >
                    First
                </button>
            </li>
            {pages.map(({ page, active }) => (
                <li className="page-item" key={page}>
                    <button
                        onClick={onPageclick}
                        className={`page-link ${active && "active" }`}
                        name={`${page}`}
                    >
                        {page}
                    </button>
                </li>
            ))}
            <li className={`page-item ${current === length && "disabled"}`}>
                <button
                    onClick={onPageclick}
                    className="page-link"
                    tabIndex={current === length ? "-1" : "0"}
                    aria-disabled={current === length}
                    name={length}
                >
                    Last
                </button>
            </li>
        </>
    );
}

export default PaginationButtons;