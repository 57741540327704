import InputWithSuggestions from "../inputWithSuggestions/inputWithSuggestions";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import ClosableItem from "./ClosableItem";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { NotificationManager } from "react-notifications";
import Loading from "../Loading/Loading";
import { getShipDataObjectByTagname } from "../../slices/shipDataSlice";

const DataTagForm = ({ shipId, dataSet, keysString, isOpen, onCancelCallback, onSaveCallback }) => {
    const dispatch = useDispatch();
    const { fullTagMeta } = useSelector((state) => state.tags);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [listForSuggestions, setListForSuggestion] = useState(null);
    const [sampleData, setSampleData] = useState(null);
    const [isLoading, setLoading] = useState(null);

    const keysLength = useMemo(() => {
        return selectedKeys.length;
    }, [selectedKeys])

    const sampleValue = useMemo(() => {
        if (!sampleData)
            return null;

        let value = sampleData;
        for (let i = 1; i < selectedKeys.length; i++) {
            value = value?.[selectedKeys[i]]
        }
        return JSON.stringify(value, null, 2);
    }, [selectedKeys, sampleData])

    const isSuggestionListEmpty = useMemo(() => {
        return listForSuggestions?.length === 0;
    }, [listForSuggestions])

    const saveButtonDisabled = useMemo(() => {
        return (keysLength === 0);
    }, [keysLength])

    const saveBtnClicked = useCallback(() => {
        onSaveCallback(shipId, dataSet, selectedKeys);
    }, [shipId, dataSet, selectedKeys, onSaveCallback])

    const itemSelected = useCallback((tagName) => {
        if (tagName) {
            setSelectedKeys([...selectedKeys, tagName]);
        }

    }, [selectedKeys])


    const removeKey = useCallback((removingKey) => {
        setSelectedKeys(selectedKeys.filter(key => key !== removingKey));
    }, [selectedKeys])

    useEffect(() => {
        let currentObject = null;
        if (keysLength > 0) {
            if (!sampleData) {
                const tagName = selectedKeys[0];
                dispatch(
                    getShipDataObjectByTagname({ shipId, tagName, setLoading }))
                    .then((res) => {
                        if (res?.payload?.data) {
                            setSampleData(res?.payload?.data)
                        } else {
                            NotificationManager.warning(`No data found for Ship: ${shipId}, tagName: ${tagName}`)
                        }
                        setLoading(false)
                    });
            }
            currentObject = sampleData;
            selectedKeys.forEach((key, i) => {
                if (i > 0) {
                    currentObject = sampleData?.[key];
                }
            });
        } else {
            setSampleData(null);
            currentObject = fullTagMeta[shipId]?.tagList;
        }

        setListForSuggestion(Object.keys(currentObject ?? {}));

    }, [selectedKeys, fullTagMeta, shipId, keysLength, sampleData, dispatch])

    useEffect(() => {
        if (keysString) {
            setSelectedKeys(keysString.split("."))
        }
    }, [keysString])

    return <Modal isOpen={isOpen} centered={true} >
        <ModalHeader>
            DataSet Form
        </ModalHeader>
        <ModalBody >
            <div className="container text-center ">
                <div className="row mb-3">
                    <div className="col">
                        Selected Dataset: {dataSet}
                    </div>
                    <div className="col">
                        Selected Ship : {shipId}
                    </div>
                </div>
                <div className="row text-center mb-3">
                    <Loading
                        isLoading={isLoading}
                        animationWidth={"30px"}
                        animationHeight={"30px"}
                    >
                        <InputWithSuggestions
                            selectedDataList={listForSuggestions}
                            onSelectSuggestion={itemSelected}
                            clearAfterSelection={true}
                            placeHolder={"Select a item to add."}
                            isDisabled={isSuggestionListEmpty || isLoading}
                        />
                    </Loading>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    {selectedKeys.map((key, i) =>

                        <ClosableItem
                            key={key}
                            bgClassName="bg-secondary me-1"
                            itemName={key}
                            isClosable={i === keysLength - 1}
                            closeClickedCallback={removeKey}
                        />


                    )}
                </div>

                <ModalFooter>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-primary me-1" disabled={saveButtonDisabled || isLoading} onClick={saveBtnClicked}> Save</button>
                        <button className="btn btn-secondary" onClick={() => onCancelCallback(false)}> Cancel</button>
                    </div>
                </ModalFooter>
                {sampleValue &&
                    <>
                        <hr />
                        <div className="text-start">
                            <dt>Sample Data Value</dt>
                            <dd><pre id="json" >{sampleValue}</pre></dd>
                        </div>
                    </>
                }
            </div>
        </ModalBody>
    </Modal>
}

export default DataTagForm;