import { useState } from "react";
import Loading from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  createOrUpdateDataSet,
  deleteDataSet,
  setSelectedDataTag,
} from "../../slices/dataTagNamesSlice";
import { sliceStatus } from "../../util/Consts";
import { useEffect } from "react";
import DatasetList from "../DatasetSelector/DatasetSelector";
import "./DataSet.css"
import DataSetForm from "./DataSetForm";
import DialogBox from "../DialogComponent/Dialog";

const DataSetManager = () => {
  const dispatch = useDispatch();
  const { selectedDataSet, dataSets, status } = useSelector(
    (state) => state.dataTags
  );
  const [editingDataSet, setEditingDataSet] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [currentDataSet, setCurrentDataSet] = useState(null);

  const editDataSet = useCallback(() => {
    setEditingDataSet(currentDataSet);
    setIsOpen(true)
  }, [currentDataSet]);

  const openAddDialog = useCallback(() => {
    setIsOpen(true)
  }, []);

  const onDataSetSelect = useCallback(
    (value) => {
      dispatch(setSelectedDataTag(value));
    },
    [dispatch]
  );

  const handleSave = useCallback(
    (editedData) => {
      dispatch(createOrUpdateDataSet({ content: editedData }));
      setIsOpen(false);
      dispatch(setSelectedDataTag(editedData.name))
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (e) => {
      dispatch(deleteDataSet({ dataSet: selectedDataSet }));
    },
    [selectedDataSet, dispatch]
  );

  const formClosedCallback = useCallback(() => {
    setEditingDataSet(null);
  }, [])

  useEffect(() => {
    setCurrentDataSet(dataSets?.[selectedDataSet]);
  }, [dataSets, selectedDataSet]);


  return (
    <>
      <div className="col miw-140-px mb-2 mb-md-0">
        {(
          <Loading
            isLoading={!selectedDataSet && status === sliceStatus.LOADING}
            animationWidth={"30px"}
            animationHeight={"30px"}
          >
            <DatasetList onDatasetSelect={onDataSetSelect} selectedDataset={selectedDataSet} showAll={true} />
          </Loading>
        )}
      </div>
      {
        selectedDataSet &&
        <DialogBox
          yesButtonClicked={handleDelete}
          yesButtonClassNames="btn-danger"
          triggerButtonText=""
          triggerButtonClassNames="btn-danger btn-sm btn-edit me-1 btn-square"
          dialogDescription="Do you want to delete this DataSet?"
          dialogTitle="Delete"
          yesButtonText="Delete"
          noButtonText="Cancel"
        >
          <i className="fa fa-trash" />
        </DialogBox>
      }
      {
        <>
          <button
            type="btn"
            disabled={!selectedDataSet}
            className="btn btn-primary  col-md-1 me-md-2 miw-140-px mb-2 mb-md-0"
            onClick={editDataSet}
          >
            Edit DataSet

          </button>
          <button
            type="btn"
            className="btn btn-primary  col-md-1  miw-140-px mb-2 mb-md-0"
            onClick={openAddDialog}
          >
            New DataSet
          </button>
        </>
      }

      <div>
        <DataSetForm
          dataset={editingDataSet}
          isOpen={isOpen}
          onCancelCallback={() => setIsOpen(false)}
          onClosedCallback={formClosedCallback}
          onSaveCallback={handleSave}
        />
      </div>
    </>
  );
};

export default DataSetManager;
