import { useSelector } from "react-redux";

const RequireAuthorization = ({ roles, withInfo, children }) => {
  const { role } = useSelector((state) => state.auth);
  
  return roles.includes(role) ? (
    <>{children}</>
  ) : (
    withInfo && <>You're Not Authorized.</>
  );
};

export default RequireAuthorization;
