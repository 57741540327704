import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  getAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  setAccessTokenInCookies,
  setRefreshTokenInCookies,
} from "./utils/cookieUtiils";

const RedirectToLoginPage = () => {
  const navigate = useNavigate();
  navigate.push(window.location.pathname);
  navigate.push("/login");
};

const InstanceManager = {
  instance: null,
  abortControllers: {},
  getInstance: () => {
    if (InstanceManager.instance) return InstanceManager.instance;

    const instance = axios.create({ baseURL: "/api/v1" });
    instance.interceptors.request.use((config) => {
      const token = getAccessTokenFromCookies();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      const { id } = config;
      if (id) {
        if (InstanceManager.abortControllers[id]) {
          InstanceManager.abortControllers[id].abort();
        }

        InstanceManager.abortControllers[id] = new AbortController();
        config.signal = InstanceManager.abortControllers[id].signal;
      }

      return config;
    });

    instance.interceptors.response.use(
      (response) => {
        if (response.config.id)
          delete InstanceManager.abortControllers[response.config.id];

        return response;
      },
      async (error) => {
        const { config, code } = error;
        if (code === "ERR_CANCELED") throw error;

        if (error.response.status === 401 && !config._retry) {
          config._retry = true;

          try {
            const { data } = await instance.post("auth/refresh", {
              accessToken: getAccessTokenFromCookies(),
              refreshToken: getRefreshTokenFromCookies(),
            });
            setAccessTokenInCookies(data.accessToken);
            setRefreshTokenInCookies(data.refreshToken);
            return instance(config);
          } catch (error) {
            RedirectToLoginPage();
          }
        }

        throw error;
      }
    );

    InstanceManager.instance = instance;

    return instance;
  },
};

export default InstanceManager;
