import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login } from "../slices/authSlice";
import Loading from "../components/Loading/Loading";
import PasswordField from "../components/Password/PasswordField";

const Login = () => {
  const dispatch = useDispatch(); 
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const {loading} = useSelector(state => state.auth);

  useEffect(() => {
    if (!!token) {
      const path = location?.state?.redirectTo?.pathname;
      navigate(path ? path : "/");
    }
  }, [token, location, navigate]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      dispatch(login({ email, password }));
    },
    [email, password, dispatch]
  );

  return (
    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div
            className="card bg-dark text-white"
            style={{ borderRadius: "1rem" }}
          >
            <div className="card-body p-5 text-center">
              <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
              <p className="text-white-50 mb-2">
                Please enter your login and password!
              </p> 
              <form onSubmit={handleSubmit}>
                <div className="form-outline mb-4">
                  <input
                    disabled={loading}
                    className="form-control"
                    placeholder="Email"
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div> 
                <PasswordField
                  disabled={loading}
                  className="mb-4"
                  placeholder="Password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                />
                <div>
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    <Loading
                      className="rounded mx-3"
                      isLoading={loading}
                      animationHeight={"30px"}
                      animationWidth={"30px"}
                      circleColor="#fff"
                      textColor="#fff"
                      animationOnly={true}
                    >
                      Login
                    </Loading>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
