import { useCallback, useState } from "react";
import PasswordField from "./PasswordField";
import { NotificationManager } from "react-notifications";
import InstanceManager from "../../auth/auth";

const ChangePassword = () => {
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [isWaiting, setWaiting] = useState(false);

    const onChangePassword = useCallback(async (e) => {
        e.preventDefault();

        setWaiting(true);

        const { target } = e,
            { password: passwordField, newPassword: newPasswordField, confirmPassword: confirmPasswordField } = target;

        const password = passwordField.value, newPassword = newPasswordField.value, confirmPassword = confirmPasswordField.value;
        if (newPassword.value !== confirmPassword.value) {
            NotificationManager.error("New passwords do not match.");
        } else {
            try {
                await InstanceManager.getInstance().post("/auth/change-password", null, {
                    params: {
                        password,
                        newPassword
                    }
                });

                onCancel();
                NotificationManager.success("Successfully changed password.");
            } catch (error) {
                console.log(error)
                NotificationManager.error(error.response?.data || error.message || "Something went wrong.")
            }
        }

        setWaiting(false);
    }, [])

    const onShowFields = useCallback(() => {
        setShowPasswordFields(true);
    }, []);

    const onCancel = useCallback(() => {
        setShowPasswordFields(false);
    }, [])

    return <form onSubmit={onChangePassword}>
        {
            showPasswordFields && <>
                <PasswordField className="mb-2" disabled={isWaiting} required name="password" placeholder="Current Password" />
                <PasswordField className="mb-2" disabled={isWaiting} required name="newPassword" placeholder="New Password" />
                <PasswordField className="mb-2" disabled={isWaiting} required name="confirmPassword" placeholder="Confirm New Password" />
            </>
        }
        <div>
            <button disabled={isWaiting} type="submit" className="btn btn-primary me-2" onClick={showPasswordFields ? null : onShowFields}>{showPasswordFields ? "Save" : "Change Password"}</button>
            {showPasswordFields && <button type="button" disabled={isWaiting} className="btn btn-primary" onClick={onCancel}>Cancel</button>}
        </div>
    </form>
}

export default ChangePassword;