import { useDispatch, useSelector } from "react-redux";
import { ShipList } from "../components/ShipSelector/ShipSelector";
import { useState } from "react";
import { useCallback } from "react";
import ShipMetaData from "../components/MetaData/ShipMetaData";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { updateOrInsertShipMetaDataObject } from "../slices/TagNamesSlice";
import _ from "lodash";

const ManageShipMetaDataPage = () => {
    const dispatch = useDispatch();
    const { ships: shipList, filteredTagsByShip } = useSelector(state => state.tags);

    const [selectedShip, setSelectedShip] = useState(null);
    const [isCreatingMetaData, setIsCreatingMetaData] = useState(false);
    const [newMetaData, setNewMetaData] = useState({});
    const [isWaiting, setisWaiting] = useState(false);

    const clearSelection = useCallback(() => {
        setSelectedShip(null);
    }, []);

    const handleCreatingMetaData = useCallback(() => {
        setIsCreatingMetaData(state => !state);
    }, []);

    const createNewMetaData = useCallback(async () => {
        setisWaiting(true);
        
        if (newMetaData["key"])
            await dispatch(updateOrInsertShipMetaDataObject({ data: newMetaData, shipId: selectedShip }));
        setNewMetaData({});

        setisWaiting(false);
        setIsCreatingMetaData(false);
    }, [newMetaData, dispatch, selectedShip]);

    const changeMetaData = useCallback((e) => {
        newMetaData[e.target.name] = e.target.value;
        setNewMetaData(_.cloneDeep(newMetaData));
    }, [newMetaData]);

    return <div className="container">
        <div className="row mb-4">
            <div className="col">
                <ShipList selectedShip={selectedShip} onShipSelect={setSelectedShip} clearFunction={clearSelection} shipList={shipList} withoutMetaData={true} />
            </div>
        </div>

        {
            selectedShip && <>
                <div className="row justify-content-center align-items-center mb-3">
                    <div className="col fit-content">Ship MetaData</div>
                    <div className="col ms-2 fit-content">
                        <button type="btn" className="btn btn-primary" onClick={handleCreatingMetaData}>
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                    {Object.entries(filteredTagsByShip?.[selectedShip]?.ship?.metadata || {}).map((entry, index) => {
                        return <ShipMetaData key={index} data={{ key: entry[0], value: entry[1] }} shipId={selectedShip} />
                    })}
                </div>

                <Modal isOpen={isCreatingMetaData} toggle={handleCreatingMetaData} >
                    <ModalHeader toggle={handleCreatingMetaData}>Create DataSet</ModalHeader>
                    <ModalBody>
                        <input
                            className="form-control col mb-2"
                            type="text"
                            name="key"
                            defaultValue={newMetaData?.["key"]}
                            onChange={changeMetaData}
                            placeholder="MetaData Key"
                        />
                        <input
                            className="form-control col my-2 mb-md-0"
                            type="text"
                            placeholder="MetaData Value"
                            defaultValue={newMetaData?.["value"]}
                            onChange={changeMetaData}
                            name="value"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="btn"
                            className="btn btn-primary" onClick={createNewMetaData}
                            disabled={isWaiting || !newMetaData?.["key"] || !newMetaData?.["value"]}>
                            Save
                        </button>
                    </ModalFooter>
                </Modal>
            </>
        }
    </div>
}

export default ManageShipMetaDataPage;