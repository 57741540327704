import { useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";

const TimeRangeFilter = ({ onTimeChange, onFilterClick, startTime, endTime,buttonName,dependencies, isDisabled  }) => {
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);

  const isButtonDisabled =
    startTime === selectedStartTime && endTime === selectedEndTime;

  const updateTimeRanges = useCallback(() => {
    onFilterClick
      &&
      onFilterClick({
        startTime: selectedStartTime
          ? selectedStartTime.getTime()
          : null,
        endTime: selectedEndTime
          ? selectedEndTime.getTime()
          : null,
      });
  }, [
    selectedStartTime,
    selectedEndTime,
    onFilterClick
  ]);

  const handleSetStartTime = useCallback(
    (timestamp) => {
      setSelectedStartTime(timestamp);
      onTimeChange && onTimeChange();
    },[onTimeChange]);

  const handleSetEndTime = useCallback(
    (timestamp) => {
      setSelectedEndTime(timestamp);
      onTimeChange && onTimeChange();
    },[onTimeChange]);

  return (
    <div className="row mb-3 d-flex justify-content-center text-center text-md-start">
      <label className="col-md mb-2 mb-md-0">
        Start Time:
        <ReactDatePicker
          disabled={isDisabled}
          className="form-control"
          timeIntervals={1}
          onChange={handleSetStartTime}
          selected={selectedStartTime || startTime}
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          showTimeSelect
          showIcon
        />
      </label>

      <label className="col-md mb-2 mb-md-0">
        End Time:
        <ReactDatePicker
          disabled={isDisabled}
          className="form-control"
          timeIntervals={1}
          onChange={handleSetEndTime}
          selected={selectedEndTime || endTime}
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          showTimeSelect
          showIcon
        />
      </label>
      <div className="col-md d-flex align-items-end justify-content-center">
        <button
          className="btn btn-primary form-control"
          onClick={updateTimeRanges}
          disabled={isButtonDisabled || isDisabled}
        >
          {buttonName??"Filter"}
        </button>
      </div>
    </div>
  );
};
export default TimeRangeFilter;
