import { useCallback, useState } from "react";

const ElementsPerPage = ({ elementsPerPage, setElementsPerPage }) => {
    const [inputValue, setInputValue] = useState(elementsPerPage.toString());

    const handleInputChange = useCallback((e) => {
        const value = e.target.value;
        setInputValue(value);
        setElementsPerPage(parseInt(value));
    }, [setElementsPerPage]);

    return (
        <div className="fit-content mt-3 m-auto m-lg-0">
            <input
                type="number"
                min={1}
                max={30}
                placeholder="Elements Per Page"
                className="form-control fit-content"
                onChange={handleInputChange}
                value={inputValue}
                style={{ minWidth: "180px" }}
            />
        </div>
    );
}

export default ElementsPerPage;