import { useCallback, useState } from "react";
import './PasswordField.css'

const PasswordField = ({ className, ...rest }) => {
    const [showPassword, setShowPassword] = useState(false);

    const onShowHidePasswordClick = useCallback(() => {
        setShowPassword(state => !state);
    }, []);

    return <>
        <div className={`input-group  ${className}`}>
            <input className="form-control" {...rest} type={showPassword ? "text" : "password"} />
            <button type="button" className="btn btn-outline-secondary bg-white border-start-0 ps-show-hide" onClick={onShowHidePasswordClick}>
                <i className={`fa fa-eye${showPassword ? "-slash" : ""}`}></i>
            </button>
        </div>
    </>;
}

export default PasswordField;