import React, { useCallback, useEffect, useState } from "react";
import { ShipList } from "../components/ShipSelector/ShipSelector";
import TimeRangeFilter from "../components/TimeRangeFilter/TimeRangeFilter";
import "../components/ShipDetails/ShipDetails.css";
import { useDispatch, useSelector } from "react-redux";
import ShipDataMap from "../components/ShipDataMap/shipDataMap";
import DatasetList from "../components/DatasetSelector/DatasetSelector";
import { clearData, fetchDataSet, fetchpathForShip, selectDataSet, selectShip, setTimeRange } from "../slices/datasetSlice";
import { sliceStatus } from "../util/Consts";


const ShipDataMapPage = () => {

    const dispatch = useDispatch();

    const { selectedShip, selectedDataset, timeRange: { startTime, endTime }, status } = useSelector(state => state.dataset);
    const { ships } = useSelector(state => state.tags);
    const { dataSets: datasetList } = useSelector((state) => state.dataTags);

    const [currentShip, setCurrentShip] = useState(selectedShip);
    const [currentDataset, setCurrentDataset] = useState(null);
    const [filteredShips, setFilteredShips] = useState(ships);

    const onFilterButtonClicked = useCallback((updatedTimeRange) => {

        const { startTime: updatedStartTime, endTime: updatedEndTime } = updatedTimeRange;

        const fetchDataOptions = {
            shipId: currentShip,
            startTime: updatedStartTime || startTime,
            endTime: updatedEndTime || endTime
        };

        if (updatedStartTime !== startTime || updatedEndTime !== endTime) {
            dispatch(clearData());
            dispatch(setTimeRange({ shipId: currentShip, startTime: fetchDataOptions.startTime, endTime: fetchDataOptions.endTime }));
        }

        if (currentShip !== selectedShip) {
            dispatch(selectShip(currentShip));
        }

        if (updatedStartTime !== startTime || updatedEndTime !== endTime || currentShip !== selectedShip) {
            dispatch(fetchpathForShip(fetchDataOptions));
        }

        if (currentDataset !== selectedDataset) {
            dispatch(selectDataSet(currentDataset));
        }

        dispatch(fetchDataSet({ ...fetchDataOptions, dataset: currentDataset }));

    }, [dispatch, startTime, endTime, currentShip, currentDataset, selectedDataset, selectedShip]);

    const clearSelectedDataSet = useCallback(() => {
        dispatch(selectDataSet(null));
    }, [dispatch]);

    const clearSelectedShip = useCallback(() => {
        dispatch(selectShip(null));
    }, [dispatch]);
 
    useEffect(() => {
        if (selectedDataset || currentDataset) {
            setFilteredShips(ships.filter(ship => datasetList[currentDataset || selectedDataset]?.dataTagList?.some(e => e.imo === ship.imo))); 
        }
        else {
            setFilteredShips(ships);
        }
    }, [ships, selectedDataset, currentDataset, datasetList])

    return (
        <div className="row vw-100">
            <div className="col-12 ">
                <div className="row mb-3">
                    <div className="col-md-6 mb-2 mb-md-0">
                        <ShipList
                            shipList={filteredShips}
                            onShipSelect={setCurrentShip}
                            selectedShip={currentShip || selectedShip}
                            clearFunction={clearSelectedShip}
                        ></ShipList>
                    </div>
                    <div className="col-md-6">
                        <DatasetList
                            clearFunction={clearSelectedDataSet}
                            onDatasetSelect={setCurrentDataset}
                            selectedDataset={currentDataset || selectedDataset}
                            selectedShip={currentShip || selectedShip}
                            showAll={!currentShip}
                        />
                    </div>
                </div>
                <TimeRangeFilter
                    isDisabled={status === sliceStatus.LOADING || !(currentDataset || selectedDataset)}
                    startTime={startTime}
                    endTime={endTime}
                    onFilterClick={onFilterButtonClicked}
                    buttonName={"Load Data"}
                    dependencies={[currentDataset, currentShip]}
                ></TimeRangeFilter>
                <div className="shipdata-map-container">
                    <ShipDataMap />
                </div>
            </div>
        </div>
    );
};

export { ShipDataMapPage };
