const getTokenContent = (token) => {
  return (token || "").split(".");
};

const getClaim = (token, claim) => {
  const [, encodedPayload] = getTokenContent(token);

  try {
    const payload = JSON.parse(atob(encodedPayload));

    return payload[claim] || null;
  } catch {
    return null;
  }
};

export { getTokenContent, getClaim };
