import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputWithSuggestions from "../inputWithSuggestions/inputWithSuggestions";
import { selectTagname } from "./../../slices/TagNamesSlice";
import { VIEW_POP_UP_ELEMENT_IDS, sliceStatus } from "../../util/Consts";
import Loading from "../Loading/Loading";
import { closeSection, openSection } from "../../slices/viewPopUpSlice";

const TagList = ({ tagList, currentTagName, tagNameSelectedCallback }) => {
  const dispatch = useDispatch();
  const { filteredTagsByShip, selectedShip, selectedTagname, status, error } =
    useSelector((state) => state.tags);

  const dataList = useMemo(() => {
    return Object.values(tagList || filteredTagsByShip[selectedShip]?.tagList || {});
  }, [filteredTagsByShip, selectedShip, tagList]);

  const handleSelectTagname = useCallback(
    (tagname) => {
      if (tagNameSelectedCallback) {
        tagNameSelectedCallback(tagname)
      } else { dispatch(selectTagname(tagname)); }
    },
    [dispatch, tagNameSelectedCallback]
  );

  useEffect(() => {
    if (!selectedTagname || selectedTagname === "")
      dispatch(closeSection(VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA))
    else
      dispatch(openSection(VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA))
  }, [selectedTagname]);

  useEffect(() => {
    if (selectedTagname)
      dispatch(openSection(VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA))

    return () => dispatch(closeSection(VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA))
  }, []);

  return (
    <Loading
      isLoading={status === sliceStatus.LOADING}
      className={"d-flex align-items-center"}
      text="Loading tag names..."
      animationHeight={"28px"}
      animationWidth={"28px"}
      circleColor="#000"
    >
      {status === sliceStatus.SUCCEEDED ? (
        <InputWithSuggestions
          selectedDataList={dataList}
          onSelectSuggestion={handleSelectTagname}
          isDisabled={tagList ? false : !selectedShip}
          selectedItem={selectedTagname || currentTagName}
          placeHolder="Select a Tag Name"
          itemKeyField="tagName"
          itemDescField="description"
        />
      ) : (
        <div>{error}</div>
      )}
    </Loading>
  );
};

export default TagList;
