import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../auth/auth";
import {
  getAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  removeTokensFromCookies,
  setAccessTokenInCookies,
  setRefreshTokenInCookies,
} from "../auth/utils/cookieUtiils";
import { getClaim } from "../auth/utils/jwtUtil"; 
import { NotificationManager } from "react-notifications";
import InstanceManager from "../auth/auth";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const response = await InstanceManager.getInstance().post("/auth", { email, password });
      return { data: response.data, email };
    } catch (error) {
      NotificationManager.error(error.response.data);
      return rejectWithValue();
    }
  }
);

const accessToken = getAccessTokenFromCookies();

const initialState = {
  token: accessToken,
  refreshToken: getRefreshTokenFromCookies(),
  loading: false,
  error: null,
  role: getClaim(accessToken, "role"),
  email: getClaim(accessToken, "unique_name"),
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.role = null;
      state.refreshToken = null;
      removeTokensFromCookies();
    },
  },
  extraReducers: (cases) => {
    cases
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;

        const { accessToken, refreshToken } = action.payload.data;

        state.token = accessToken;
        state.refreshToken = refreshToken;
        state.role = getClaim(accessToken, "role");
        state.email = getClaim(accessToken, "unique_name");

        setAccessTokenInCookies(accessToken);
        setRefreshTokenInCookies(refreshToken);
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
