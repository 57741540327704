import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import instance from "../auth/auth";
import { ROLES } from "../auth/RoleManager";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Loading from "../components/Loading/Loading";
import { NotificationManager } from "react-notifications";
import InstanceManager from "../auth/auth";
import PasswordField from "../components/Password/PasswordField";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [role, setRole] = useState("client");
  const [isWaiting, setWaiting] = useState(false);
  const [isToggled, setToggled] = useState(false);

  const handleRegister = useCallback(async () => {
    setWaiting(true);

    if (password !== verifyPassword) {
      NotificationManager.error("Passwords do not match");

      setWaiting(false);
      return;
    }
    try {
      const response = await InstanceManager.getInstance().post(
        "/auth/register",
        {
          email,
          password,
          role,
        }
      );
      if (response.status === 200) {
        NotificationManager.success("Successfully created a new user");

        setEmail("");
        setPassword("");
        setVerifyPassword("");
        setRole("client");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message?.description;
      if (errorMessage) {
        NotificationManager.error(errorMessage);
      } else {
        NotificationManager.error("Something went wrong.");
        console.log(error);
      }
    }

    setWaiting(false);
  }, [password, verifyPassword, email, role]);

  const handleRoleChange = useCallback((event) => {
    setTimeout(() => {
      setToggled(false);
    }, 500);
    setRole(event.currentTarget.name);
  }, []);

  return (
    <div className="container h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-9 col-lg-7 col-xl-6">
          <div
            className="card bg-dark  text-white"
            style={{ borderRadius: "15px" }}
          >
            <div className="card-body p-5">
              <h2 className="text-uppercase text-center mb-3">
                Create an account
              </h2>
              <form>
                <div className="form-outline mb-4">
                  <input
                    disabled={isWaiting}
                    type="email"
                    id="email"
                    className="form-control"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <PasswordField
                  disabled={isWaiting}
                  id="password"
                  className="mb-4"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />

                <PasswordField
                  disabled={isWaiting}
                  id="verifypassword"
                  className="mb-4"
                  value={verifyPassword}
                  placeholder="Verify Password"
                  onChange={(e) => setVerifyPassword(e.target.value)}
                />

                <div className="mb-4">
                  <Dropdown
                    isOpen={isToggled}
                    disabled={isWaiting}
                    toggle={() => setToggled((state) => !state)}
                    className="w-100 mx-auto position-relative"
                  >
                    <DropdownToggle className="w-100" caret>
                      {role.charAt(0).toUpperCase() + role.slice(1)}
                    </DropdownToggle>
                    <DropdownMenu className="user-role-selector mw-100 w-100">
                      {Object.entries(ROLES).map(
                        ([key, { name, description }]) =>
                          name !== ROLES.SUPERUSER.name && (
                            <DropdownItem
                              key={key}
                              className="mw-100"
                              name={name}
                              onClick={handleRoleChange}
                            >
                              <dt className="lead fw-bold">
                                {name.charAt(0).toUpperCase() + name.slice(1)}
                              </dt>
                              <dd className="mw-100 text-wrap">
                                {description}
                              </dd>
                            </DropdownItem>
                          )
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    disabled={isWaiting}
                    type="button"
                    className="btn btn-success btn-block btn-lg gradient-custom-4 text-white"
                    onClick={handleRegister}
                  >
                    <Loading
                      className="rounded mx-3"
                      isLoading={isWaiting}
                      animationHeight={"26px"}
                      animationWidth={"26px"}
                      circleColor="#fff"
                      textColor="#fff"
                      animationOnly={true}
                    >
                      Register
                    </Loading>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
