import React, { useCallback, useMemo } from "react";
import "./Sidebar.css";
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { ListGroupItem, ListGroup } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import Metrics from "./Metrics";
import { VIEW_POP_UP_ELEMENT_IDS } from "../../util/Consts";

const Sidebar = () => {
  const selectedTagName = useSelector((state) => state.tags.selectedTagname);
  const { tagMetaData } = useSelector((state) => state.shipData);
  const [activeTab, setActiveTab] = useState("1");
  const metadata = tagMetaData[selectedTagName || ""];
  const { [VIEW_POP_UP_ELEMENT_IDS.TAG_META_DATA]: data = {} } = useSelector(state => state.viewPopUp.data);
  const { dataKeys = [] } = data;

  const metakeys = metadata ? Object.keys(metadata) : [];

  const toggleTab = useCallback(
    (tab) => {
      tab !== activeTab && setActiveTab(tab);
    },
    [activeTab]
  );
  
  return (
    <div className="container">
      <Nav tabs fill>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            Metadata
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            Metrics
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ListGroup flush={true}>
            {metakeys?.length > 0 &&
              metakeys?.map((key) => (
                <div key={key}>
                  {metadata?.[key] && !(metadata?.[key] instanceof Object) && (
                    <ListGroupItem>
                      <span className="row px-3 row-key">{key} </span>
                      <span className="row px-3 row-value">
                        {metadata?.[key]}
                      </span>
                    </ListGroupItem>
                  )}
                </div>
              ))}
          </ListGroup>
        </TabPane>
        <TabPane tabId="2">
          <Metrics dataKeys={dataKeys} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Sidebar;
