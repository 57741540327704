import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";
import { MS_FOR_ONE_YEAR, sliceStatus } from "../util/Consts";
import InstanceManager from "../auth/auth";

const fetchData = async (
  { shipId, tagName, from, to, all },
  { getState, dispatch }
) => {
  const {
    shipData: { elementsPerPage, messageData, tagMetaData },
  } = getState();

  const currentDdata = messageData[tagName];

  if (currentDdata && currentDdata.length > 0) {
    return [{ shipData: currentDdata, tagMetaData }, tagName];
  } else {
    const elementCount = elementsPerPage * 3;

    let data = {
      shipData: [],
    };
    try {
      if (all && from) {
        const now = new Date().getTime();
        let tmpTo = Math.min(from + MS_FOR_ONE_YEAR, to || now),
          tmpFrom = from || null;
        while (tmpTo <= (to || now)) {
          const {
            data: { shipData, ...rest },
          } = await InstanceManager.getInstance().get(`/${shipId}/${tagName}`, {
            params: {
              start: tmpFrom,
              end: tmpTo,
              elementCount: all ? null : elementCount,
            },
            id: fetchShipDataByTag.typePrefix,
          });

          data = {
            shipData: shipData.concat(data.shipData),
            ...rest,
          };

          if (tmpTo === now) break;

          if (tmpTo) {
            tmpFrom = tmpTo;
            tmpTo += MS_FOR_ONE_YEAR;
          } else tmpTo = now;

          tmpTo = Math.min(tmpTo, now);
        }
      } else {
        const { data: tmpData } = await InstanceManager.getInstance().get(
          `/${shipId}/${tagName}`,
          {
            params: {
              start: from ? from : null,
              end: to ? to : null,
              elementCount: all ? null : elementCount,
            },
            id: fetchShipDataByTagWithLimit.typePrefix,
          }
        );

        data = tmpData;
      }
    } catch (error) {
      if (error.code !== "ERR_CANCELED")
        NotificationManager.error("Something went wrong.");
      throw error;
    }

    const dataCount = data.shipData.length;
    if (!all && dataCount === elementCount) {
      dispatch(
        fetchShipDataByTag({
          shipId: shipId,
          tagName: tagName,
          from: from,
          to: to,
          all: true,
        })
      );
    }

    return [data, tagName];
  }
};

const getShipDataObjectByTagname = createAsyncThunk("messages/getOneShipDataObject",
  async ({shipId, tagName, setLoading}) => {
    setLoading && setLoading(true);
    if (!tagName || tagName === "") {
      setLoading && setLoading(false);
      return {};
    }

    try {
      const { status, data, statusText } = await InstanceManager.getInstance().get(
        `/${shipId}/${tagName}`,
        {
          params: {
            oneOfAny: true,
            elementCount: 1,
          },
        }
      );
      if (status >= 200 && status < 300) {
        setLoading && setLoading(false);
        return data;
      } else NotificationManager.error(statusText);
    } catch (error) {
      NotificationManager.error("Something went wrong");
      console.log(error);
    }
    setLoading && setLoading(false);
  },
  []
);

export const fetchShipDataByTagWithLimit = createAsyncThunk(
  "messages/fetchMessageDataByTagWithLimit",
  fetchData
);

export const fetchShipDataByTag = createAsyncThunk(
  "messages/fetchMessageDataByTag",
  fetchData
);

const initialState = {
  messageData: {},
  tagMetaData: {},
  timeRange: {
    startTime: null,
    endTime: null,
  },
  paths: {},
  status: "idle",
  error: null,
  elementsPerPage: 15,
};

const shipDataSlice = createSlice({
  name: "shipData",
  initialState,
  reducers: {
    setElementsPerPage: (state, action) => {
      state.elementsPerPage = action.payload;
    },
    setShipData: (state, action) => {
      const messages = action.payload[0];
      const currentTagName = action.payload[1];
      if (messages["shipData"].length > 0) {
        state.messageData[currentTagName] = messages["shipData"];
        state.tagMetaData[currentTagName] = messages["tagMetaData"];
      } else {
        state.messageData[currentTagName] = [];
        state.tagMetaData[currentTagName] = {};
      }
    },
    setMap: (state, action) => {
      state.map = action.payload;
    },
    clearData: (state, action) => {
      state.messageData = {};
    },
    setTimeRange: (state, action) => {
      state.timeRange = action.payload;
    },
    reset: (state, action) => {
      state.endTime = null;
      state.startTime = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShipDataByTagWithLimit.pending, (state, action) => {
        state.status = sliceStatus.LOADING;
      })
      .addCase(fetchShipDataByTagWithLimit.fulfilled, (state, action) => {
        state.status = sliceStatus.SUCCEEDED;
        shipDataSlice.caseReducers.setShipData(state, action);
      })
      .addCase(fetchShipDataByTag.fulfilled, (state, action) => {
        shipDataSlice.caseReducers.setShipData(state, action);
      })
      .addCase(fetchShipDataByTag.rejected, (state, action) => {
        if (action.error.code !== "ERR_CANCELED")
          state.status = sliceStatus.FAILED;
      })
      .addCase(fetchShipDataByTagWithLimit.rejected, (state, action) => {
        if (action.error.code !== "ERR_CANCELED")
          state.status = sliceStatus.FAILED;
      });
  },
});

export const {
  setShipData,
  setElementsPerPage,
  clearData,
  setMap,
  setTimeRange,
  reset,
} = shipDataSlice.actions;

export { getShipDataObjectByTagname };

export default shipDataSlice.reducer;
