import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FaInfoCircle } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";

export function ShipMeta({ selectedItem }) {
    const { filteredTagsByShip } = useSelector(state => state.tags);

    const shipMetadata = useMemo(() => {
        return filteredTagsByShip[selectedItem]?.ship?.metadata;
    }, [selectedItem, filteredTagsByShip]);

    return (
        <div className="mx-1 px-1">
           <FaInfoCircle id="TooltipShip" color="black" size="1.25em" opacity={1} enableBackground={0} />

            <UncontrolledTooltip
                placement="right-end"
                target="TooltipShip"
                autohide={false}
            >
                {
                    shipMetadata ? Object.keys(shipMetadata).map((key) => {
                        return <div key={key}>{key}: {shipMetadata[key]}</div>
                    }
                    ) : <span>No metadata available</span>
                }
            </UncontrolledTooltip>
        </div>)
}