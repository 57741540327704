export const sliceStatus = Object.freeze({
  LOADING: "loading",
  FAILED: "failed",
  SUCCEEDED: "succeeded",
});

export const VIEW_POP_UP_ELEMENT_IDS = {
  TAG_META_DATA: "tag-meta-data"
}

export const MS_FOR_ONE_HOUR = 1000 * 60 * 60;
export const MS_FOR_ONE_YEAR = 1000 * 60 * 60 * 24 * 365;
