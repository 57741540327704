import { useCallback } from "react";
import { useState } from "react";
import InstanceManager from "../../auth/auth";
import { NotificationManager } from "react-notifications";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { deleteShipMetaDataObject, removeShipMetaData, replaceShipMetaData, updateOrInsertShipMetaDataObject } from "../../slices/TagNamesSlice";
import { useEffect } from "react";

const ShipMetaData = ({ shipId, data }) => {
    const dispatch = useDispatch();

    const [isWaiting, setisWaiting] = useState(false);
    const [dataObject, setDataObject] = useState(_.cloneDeep(data) || {});

    const createOrUpdateShipMetaData = useCallback(async () => {
        setisWaiting(true);

        if (data?.key)
            await dispatch(updateOrInsertShipMetaDataObject({data: dataObject, shipId}))

        setisWaiting(false);
    }, [dataObject, data, shipId, dispatch]); 
    
    const deleteShipMetaData = useCallback(async () => {
        setisWaiting(true);

        await dispatch(deleteShipMetaDataObject({data, shipId}));

        setisWaiting(false);
    }, [data, shipId, dispatch]);

    const onChange = useCallback((e) => {
        dataObject[e.target.name] = e.target.value; 

        setDataObject(_.cloneDeep(dataObject))
    }, [dataObject])

    useEffect(() => {
        setDataObject(_.cloneDeep(data));
    }, [data])

    return <div className="col-8 mb-2">
        <div className="input-group">
            <span className="input-group-text">{data?.key}</span>
            <input className="form-control" disabled={isWaiting} value={dataObject?.value} name="value" placeholder="Metadata Value" onChange={onChange} />
            {data?.value !== dataObject?.value && <button className="btn btn-primary" type="btn" disabled={isWaiting} onClick={createOrUpdateShipMetaData}>Save</button>}
            <button className="btn btn-secondary" type="btn" disabled={isWaiting} onClick={deleteShipMetaData}>
                <i className="fa fa-trash"></i>
            </button>
        </div>
    </div>
};

export default ShipMetaData;