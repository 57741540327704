import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DNVRow from "./DNVRow";

import { dataChannelKeys, dataChannelDescriptions } from "./dnvConst.js";
import { updateDNVRecord } from "../../slices/dnvSlice";
import { UncontrolledTooltip } from "reactstrap";
import Pagination from "../Pagination/Pagination";
import "./DNV.css";

const DNVMetaTable = ({ tableData,selectedShip,updateStatus,authorizedToEdit }) => {
    const dispatch = useDispatch();

    const [renderingData, setRenderingData] = useState([]);
 

    const handleUpdate = useCallback((updatedChannelData) => {
        dispatch(updateDNVRecord({ updatedChannelData }));
    }, [dispatch]);
    
    return (
        selectedShip && (Object.keys(tableData).length === 0 ? <div className="text-center mt-3">No data Found</div> :
            <div>
                <div className="custom-table dnv-table">
                    <table className="table table-responsive">
                        <thead className="sticky-top bg-white">

                            <tr className="dnv-row">
                                {Object.entries(dataChannelKeys).map(([key,value]) => (

                                    <th scope="col" id={`tooltip-${key}`} className="align-middle col-auto" key={key}>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={`tooltip-${key}`}
                                        >
                                            {value}
                                        </UncontrolledTooltip>
                                        <span>{dataChannelDescriptions[key]}</span></th>

                                ))}
                                {authorizedToEdit && (
                                    <th scope="col" className="align-middle controls col-auto">Actions</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {renderingData.map((rowData, index) => (
                                <DNVRow 
                                key={index} 
                                rowData={rowData} 
                                onUpdate={handleUpdate} 
                                authorizedToEdit={authorizedToEdit} 
                                uploadingStatus={updateStatus}
                                 />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="container">
                    <div className="row my-3 justify-content-between align-items-center">
                        <Pagination
                            className="fit-content"
                            withElementsPerPage={true}
                            withJump={true}
                            data={tableData}
                            setRenderingData={setRenderingData}
                        />
                    </div>
                </div>
            </div>)
    );
};

export default DNVMetaTable
