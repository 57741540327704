import { configureStore } from '@reduxjs/toolkit';
import tagsReducer from './TagNamesSlice';
import shipDataReducer from './shipDataSlice'; 
import authReducer from './authSlice';
import timeZoneReducer from './timeZoneSlice';
import datasetReducer from './datasetSlice';
import dataTagNamesReducer from './dataTagNamesSlice';
import dnvReducer from './dnvSlice';
import viewPopUpReducer from './viewPopUpSlice';

const rootReducer = {
    tags: tagsReducer, 
    shipData: shipDataReducer,
    dataset:datasetReducer,
    auth: authReducer,
    timeZone:  timeZoneReducer,
    dataTags: dataTagNamesReducer,
    viewPopUp: viewPopUpReducer,
    dnv: dnvReducer,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;