import { Tree } from "primereact/tree";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTags, filterShips } from "../../slices/TagNamesSlice";
import { sliceStatus } from "../../util/Consts";

const FilterTree = () => {
  const dispatch = useDispatch();
  const { fullTagMeta: data, status } = useSelector((state) => state.tags);

  const [selectedKeys, setSelectedKeys] = useState(null);
  const [nodes, setNodes] = useState(null);

  const selectionChanged = useCallback(
    ({ value }) => {
      setSelectedKeys(value);
      dispatch(filterShips(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (Object.keys(data).length == 0) {
      dispatch(fetchAllTags());
    }
    return () => {
      dispatch(filterShips(null));
    };
  }, [dispatch]);

  useEffect(() => {
    const finalTree = [];
    const nodeMap = new Map();
    let treeObject = {};
    let sfiObject = {};
    let sfiKey = "";
    let tagKey = "";

    Object.values(data ?? {}).forEach(
      ({ ship: { imo, name: shipName }, tagList }) => {
        treeObject = nodeMap.get(imo);
        if (!treeObject) {
          treeObject = {
            key: imo,
            label: `${imo} ${shipName ? " - " + shipName : ""}`,
            children: [],
          };
          finalTree.push(treeObject);
          nodeMap.set(imo, treeObject);
        }

        Object.values(tagList ?? {}).forEach(
          ({ tagName, sfiGroup, description }) => {
            sfiKey = `${imo}-${sfiGroup}`;
            sfiObject = nodeMap.get(sfiKey);
            if (!sfiObject) {
              sfiObject = {
                key: imo + "-" + sfiGroup,
                label: sfiGroup,
                children: [],
              };
              treeObject.children.push(sfiObject);
              nodeMap.set(sfiKey, sfiObject);
            }

            tagKey = `${sfiKey}-${tagName}`;
            sfiObject.children.push({
              key: tagKey,
              label: `${tagName} ${description ? " - " + description : ""}`,
            });
          }
        );
      }
    );

    setNodes(
      finalTree.map(({ children, ...rest }) => {
        return {
          ...rest,
          children: children
            .map(({ children: objChildren, ...objRest }) => ({
              ...objRest,
              children: objChildren.sort((objE1, objE2) =>
                objE1.label.localeCompare(objE2.label)
              ),
            }))
            .sort((e1, e2) => e1.label.localeCompare(e2.label)),
        };
      })
    );

    setTimeout(() => {
      nodeMap.clear();
    }, 0);
  }, [data]);

  return (
    <Tree
      value={nodes}
      selectionMode="checkbox"
      selectionKeys={selectedKeys}
      onSelectionChange={(e) => selectionChanged(e)}
      filter
      loading={status === sliceStatus.LOADING}
      filterMode="lenient"
      filterBy="key,label"
      className="w-full5 md:w-30rem"
    />
  );
};
export default FilterTree;
