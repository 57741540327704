import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchShipDataByTagWithLimit,
  setElementsPerPage,
} from "../../slices/shipDataSlice";
import Pagination from "../Pagination/Pagination";
import "./shipDataTable.css";
import { flatNestedObject, formatDate } from "../../util/util";
import Loading from "../Loading/Loading";
import _ from "lodash";

const MessagesTable = ({ keys = [], onDataKeysChange }) => {
  const dispatch = useDispatch();
  const { selectedTagname, selectedShip } = useSelector((state) => state.tags);
  const { messageData, status, elementsPerPage } = useSelector(
    (state) => state.shipData
  );
  const messages = messageData[selectedTagname];
  const {timeRange} = useSelector((state) => state.shipData);
  const { timeZone } = useSelector((state) => state.timeZone);

  const [renderingData, setRenderingData] = useState([]);
  const flattedRenderingData = useMemo(() => renderingData.map(({data, ...rest}) => ({...rest, data: flatNestedObject(data)})), [renderingData]);
  const dataKeys =
    keys.length > 0 ? keys.map((key) => Object.keys(key)[0]) : [];

  useEffect(() => {
    if (selectedShip && selectedTagname) {
      dispatch(
        fetchShipDataByTagWithLimit({
          shipId: selectedShip,
          tagName: selectedTagname,
          from: timeRange.startTime,
          to: timeRange.endTime,
          taskId: "messages/fetchMessageDataByTagWithLimit",
        })
      );
    }
  }, [selectedShip, selectedTagname, timeRange, dispatch]);

  useEffect(() => {
    if (status === "succeeded" && messages && messages.length > 0) {
      const message = flatNestedObject(messages[0].data);
      const keys =typeof message==="object"? Object.entries(message).map(([key, value]) => ({
        [key]: typeof value,
      })):[{"Value":typeof message}];
      onDataKeysChange(keys);
    } else {
      onDataKeysChange([]);
    }
  }, [messages, status]);

  const changeElementsPerPage = useCallback(
    (value) => {
      dispatch(setElementsPerPage(value));
    },
    [dispatch]
  );

  if (status === "failed") {
    return <div>Something went wrong.</div>;
  }

  return (
    <Loading
      className={
        "w-100 d-flex flex-row justify-content-center align-items-center"
      }
      isLoading={status === "loading"}
      animationWidth={"50px"}
      animationHeight={"50px"}
    >
      {status === "succeeded" && dataKeys?.length > 0 ? (
        <>
          <div className="custom-table data-table">
            <table className="table table-hover table-responsive text-center">
              <thead className="sticky-table-header bg-white">
                <tr className="ship-data-row">
                  <th scope="col">Timestamp</th>
                  {dataKeys?.map((key) => (
                    <th scope="col" key={key}>
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="ship-data-body">
                { flattedRenderingData?.map(({timestamp,data}) => {
                    return (
                      <tr className="ship-data-row" key={timestamp}>
                        <td>{formatDate(true, timestamp, timeZone)}</td>
                        {dataKeys.map((key) => (
                          <td key={key}>{(typeof data==="object"? data[key]: data )+ ""}</td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="container">
            <div className="row my-3 justify-content-between align-items-center">
              <Pagination
                className="fit-content"
                withElementsPerPage={true}
                withJump={true}
                data={messages}
                setRenderingData={setRenderingData}
                setElementsPerPageOptional={changeElementsPerPage}
                elementsPerPageOptional={elementsPerPage}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="text-center w-100">
          <p>No data Found</p>
        </div>
      )}
    </Loading>
  );
};

export default MessagesTable;
