import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Sidebar.css";

const Metrics = ({ dataKeys }) => {
  const selectedTagName = useSelector((state) => state.tags.selectedTagname);
  const { messageData } = useSelector((state) => state.shipData);

  const [selectedValue, setSelectedValue] = useState({
    key: "DEFAULT",
    type: "default",
  });
  const [selectedMetric, setSelectedMetric] = useState("");
  const [metricResult, setMetricResult] = useState(null);

  useEffect(() => {
    setSelectedMetric("");
  }, [selectedValue]);

  const handleSelectChange = useCallback((e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedValue({
      key: selectedOption.value,
      type: selectedOption.dataset.datatype,
    });
  }, []);

  const handleMetricChange = useCallback((e) => {
    setSelectedMetric(e.target.name);
  }, []);

  const handleBoolean = useCallback((tagData, dataKey, isTrue) => {
    const { trueCount, falseCount } = tagData.reduce(
      (acc, element) => {
        const currentVal = element.data[dataKey];
        if (currentVal !== null) {
          currentVal ? acc.trueCount += 1 : acc.falseCount += 1;
        }
        return acc;
      },
      { trueCount: 0, falseCount: 0 }
    );
    return isTrue ? trueCount : falseCount;
  }, []);

  const handleNumber = useCallback((tagData, dataKey, metric) => {
    const { sum, count } = tagData.reduce(
      (acc, element) => {
        const currentVal = element.data[dataKey];
        if (currentVal !== null) {
          acc.sum += currentVal;
          acc.count += 1;
        }
        return acc;
      },
      { sum: 0, count: 0 }
    );
    const average = sum / count;
    return metric === "count" ? count : average;
  }, []);

  const handleMinMax = useCallback((tagData, dataKey, metric) => {
    const { min, max } = tagData.reduce(
      (acc, element) => {
        const currentVal = element.data[dataKey];
        if (currentVal !== null) {
          acc.min = Math.min(acc.min, currentVal);
          acc.max = Math.max(acc.max, currentVal);
        }
        return acc;
      },
      { min: Infinity, max: -Infinity }
    );
    return metric === "min" ? min : max;
  }, []);

  useEffect(() => {
    if (!selectedMetric) {
      setMetricResult(null);
    }

    const dataKey = selectedValue.key;
    const tagData = messageData[selectedTagName];

    switch (selectedMetric) {
      case "count":
        setMetricResult(handleNumber(tagData, dataKey, "count"));
        break;
      case "avg":
        setMetricResult(handleNumber(tagData, dataKey, "avg"));
        break;
      case "min":
        setMetricResult(handleMinMax(tagData, dataKey, "min"));
        break;
      case "max":
        setMetricResult(handleMinMax(tagData, dataKey, "max"));
        break;
      case "trueCount":
        setMetricResult(handleBoolean(tagData, dataKey, true));
        break;
      case "falseCount":
        setMetricResult(handleBoolean(tagData, dataKey, false));
        break;
      default:
        break;
    }
  }, [selectedMetric, selectedValue]);

  return (
    <div>
      <div className="d-flex justify-content-center px-5 pt-3">
        {dataKeys && (
          <select
            defaultValue="DEFAULT"
            className="form-select"
            onChange={handleSelectChange}
          >
            <option
              disabled
              key="DEFAULT"
              value="DEFAULT"
              data-datatype="default"
            >
              Select an option
            </option>
            {dataKeys.map((item) => {
              const key = Object.keys(item)[0];
              const val = item[key];
              return (
                (val === "number" || val === "boolean") && (
                  <option key={key} value={key} data-datatype={val}>
                    {key}
                  </option>
                )
              );
            })}
          </select>
        )}
      </div>
      <hr />
      {selectedValue?.type !== "default" ? (
        selectedValue.type === "number" ? (
          <div className="px-auto d-flex flex-wrap justify-content-center align-items-center">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check"
                name="avg"
                id="btnradio1"
                autoComplete="off"
                onChange={handleMetricChange}
                checked={selectedMetric === "avg"}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio1">
                Average
              </label>

              <input
                type="radio"
                className="btn-check"
                name="count"
                id="btnradio2"
                autoComplete="off"
                onChange={handleMetricChange}
                checked={selectedMetric === "count"}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio2">
                Count
              </label>

              <input
                type="radio"
                className="btn-check"
                name="min"
                id="btnradio3"
                autoComplete="off"
                onChange={handleMetricChange}
                checked={selectedMetric === "min"}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio3">
                Min
              </label>

              <input
                type="radio"
                className="btn-check"
                name="max"
                id="btnradio4"
                autoComplete="off"
                onChange={handleMetricChange}
                checked={selectedMetric === "max"}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio4">
                Max
              </label>
            </div>
          </div>
        ) : (
          <div className="px-auto d-flex flex-wrap justify-content-center align-items-center">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check"
                name="trueCount"
                id="btnradio1"
                autoComplete="off"
                onChange={handleMetricChange}
                checked={selectedMetric === "trueCount"}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio1">
                True Count
              </label>

              <input
                type="radio"
                className="btn-check"
                name="falseCount"
                id="btnradio2"
                autoComplete="off"
                onChange={handleMetricChange}
                checked={selectedMetric === "falseCount"}
              />
              <label className="btn btn-outline-primary" htmlFor="btnradio2">
                False Count
              </label>
            </div>
          </div>
        )
      ) : (
        <div className="card mt-3">
          <div className="card-body">Please select a value above</div>
        </div>
      )}
      <hr />
      {selectedValue?.key !== "DEFAULT" && selectedMetric && (
        <div className="card mt-3">
          <div className="card-body">{metricResult}</div>
        </div>
      )}
    </div>
  );
};

export default Metrics;
