import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCallback, useState } from "react";

const DialogBox = ({
    yesButtonClicked,
    noButtonClicked,
    triggerButtonText,
    yesButtonText,
    noButtonText,
    dialogTitle,
    dialogDescription,
    triggerButtonClassNames,
    yesButtonClassNames,
    noButtonClassNames,
    onDialogClosed,
    children
}) => {


    const [isOpen, setIsOpen] = useState(false);
    const handleClickOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen])


    const handleYesClicked = useCallback(() => {
        yesButtonClicked?.();
        setIsOpen(false);
    }, [yesButtonClicked])

    const handleNoClicked = useCallback(() => {
        noButtonClicked?.();
        setIsOpen(false);
    }, [noButtonClicked])

    return (
        <>
            <button className={`btn my-auto ${triggerButtonClassNames ?? "btn-primary"}`} onClick={handleClickOpen}>
                {triggerButtonText ?? "Open"}{children}
            </button>
            <Dialog
                open={isOpen}
                onClose={onDialogClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle ?? "Alert"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogDescription ?? "Are you sure?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className={`btn ${noButtonClassNames ?? "btn-primary"}`} onClick={handleNoClicked}>{noButtonText ?? "No"}</button>
                    <button className={`btn ${yesButtonClassNames ?? "btn-primary"}`} onClick={handleYesClicked} autoFocus> {yesButtonText ?? "yes"}</button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default DialogBox;