import React, { useState, useEffect, useCallback, useRef } from "react";
import "./InputWithSuggestions.css";

const InputWithSuggestions = ({
  selectedDataList,
  onSelectSuggestion,
  onInputChange,
  isDisabled,
  selectedItem,
  placeHolder,
  itemKeyField,
  itemDescField,
  className,
  clearAfterSelection,
  inputDomName,
  clearFunction
}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isListOpen, setListOpen] = useState(false);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const listContainerRef = useRef(null);

  useEffect(() => {
    if (!selectedDataList) return;
    if (itemKeyField) {
      const filteredSuggestions = selectedDataList.filter((suggestion) => {
        if (inputValue) {
          return (
            suggestion[itemKeyField]?.toLowerCase()
              .includes(inputValue?.toLowerCase()) ||
            suggestion[itemDescField]?.toLowerCase()
              .includes(inputValue?.toLowerCase())
          );
        } else {
          return true;
        }
      });
      if (
        filteredSuggestions.length === 1 &&
        inputValue &&
        (filteredSuggestions[0][itemKeyField] === inputValue ||
          filteredSuggestions[0][itemDescField] === inputValue)
      ) {
        setSuggestions([]);
      } else {
        setSuggestions(filteredSuggestions);
      }
    } else {
      const filteredSuggestions = selectedDataList.filter((suggestion) => {
        return suggestion.toLowerCase().includes(inputValue.toLowerCase());
      });

      if (
        filteredSuggestions.length === 1 &&
        filteredSuggestions[0] === inputValue
      ) {
        setSuggestions([]);
      } else {
        setSuggestions(filteredSuggestions);
      }
    }
  }, [inputValue, selectedDataList, itemKeyField, itemDescField]);


  const selectedItemChange = useCallback((suggestion) => {
    if (!suggestion) {
      setInputValue("");
      onSelectSuggestion(null);
      return;
    }

    if (itemKeyField) {
      setInputValue(
        (suggestion[itemDescField] && suggestion[itemDescField] !== ""
          ? suggestion[itemDescField]
          : suggestion[itemKeyField]) || ""
      );
      onSelectSuggestion(suggestion[itemKeyField]);
    } else {
      setInputValue(suggestion);
      onSelectSuggestion(suggestion);
    }
  },
    [itemDescField, itemKeyField, onSelectSuggestion]
  );

  useEffect(() => {
    if (selectedItem) {
      if (itemKeyField) {
        const selectedObject = selectedDataList?.filter(
          (item) =>
            item[itemKeyField] === selectedItem ||
            item[itemDescField] === selectedItem
        );
        if (selectedObject?.length > 0) {
          selectedItemChange(selectedObject[0]);
        } else {
          selectedItemChange("");
        }
      } else {
        if (selectedDataList && selectedDataList.includes(selectedItem)) {
          selectedItemChange(selectedItem);
        } else {
          selectedItemChange("");
        }
      }
    }
  }, [
    selectedDataList,
    itemKeyField,
    itemDescField,
    selectedItemChange,
    selectedItem,
  ]);

  const handleInputChange = useCallback(
    (e) => {
      if (onInputChange) {
        onInputChange(e.target.value);
      }
      setInputValue(e.target.value);
    },
    [onInputChange]
  );

  const handleSuggestionClick = useCallback(
    (suggestion) => {
      selectedItemChange(suggestion);
      if (suggestion) {
        setSuggestions([]);
      }

      if (clearAfterSelection) {
        selectedItemChange(null);
        setListOpen(false);
      }
    },
    [selectedItemChange, clearAfterSelection]
  );

  const clearSelection = useCallback(
    (e) => {
      handleSuggestionClick(null);
      clearFunction && clearFunction(null);
    },
    [handleSuggestionClick, clearFunction]
  );

  useEffect(() => {
    const handleListItemCLick = (event) => {
      if (
        inputRef.current &&
        listRef.current &&
        listContainerRef.current &&
        !inputRef.current.contains(event.target) &&
        !listRef.current.contains(event.target) &&
        !listContainerRef.current.contains(event.target)
      ) {
        setListOpen(false);
      }
    };
    document.addEventListener('click', handleListItemCLick);
    return () => {
      document.removeEventListener('click', handleListItemCLick);
    };
  }, [setListOpen])

  return (
    <div ref={listContainerRef} className="w-100">
      <div className="input-group justify-content-center w-100">
        <input
          type="text"
          value={inputValue??""}
          ref={inputRef}
          autoComplete="off"
          onChange={handleInputChange}
          onFocus={() => setListOpen(true)}
          disabled={isDisabled}
          className={`form-control suggestions-input ${className}`}
          placeholder={placeHolder}
          name={inputDomName}
          style={{ borderRightWidth: (clearAfterSelection ? "1px" : "") }}
        />
        {
          !clearAfterSelection && <button
            disabled={(!inputValue && inputValue === "") || isDisabled}
            className="btn btn-primary input-group-text clear-btn fw-bold"
            id="add-page-num"
            onClick={clearSelection}
          >
            &#x2715;
          </button>
        }
      </div>
      {isListOpen && !isDisabled && (
        <ul ref={listRef} className="list-group shadow  bg-white rounded suggestions">
          {suggestions.map((suggestion) => (
            <li
              key={itemKeyField ? suggestion[itemKeyField] : suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
              className="list-group-item list-group-item-action "
            >
              {itemKeyField ? (
                <>
                  <dt>{suggestion[itemKeyField]}</dt>
                  <dd className="small">{suggestion[itemDescField]}</dd>
                </>
              ) : (
                <span> {suggestion} </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InputWithSuggestions;
