const ROLES = {
  SUPERUSER: {
    name: "super-admin",
  },
  ADMIN: {
    name: "admin",
    description: "Manage Users, View/Edit Tag Meta, View Ship Data",
  },
  AUTHOR: {
    name: "author",
    description: "View/Edit Tag Meta, View Ship Data",
  },
  CLIENT: {
    name: "client",
    description: "View Tag Meta, View Ship Data",
  },
};

export { ROLES };
