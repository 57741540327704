import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Pagination from "../Pagination/Pagination";
import "./MetaTable.css";
import { convertStringToHumanReadable } from "../../util/util";
import { ROLES } from "../../auth/RoleManager";
import DecimalField from "./DecimalField";
import TagMetaForm from "./TagMetaForm";
import _ from "lodash";

const TagsMetaTable = ({ tableData, updateClickCallback, asyncEdit }) => {

  const [renderingData, setRenderingData] = useState([]);
  const [editingData, setEditingData] = useState(null);

  const openEditingForm = useCallback((e) => {
    setEditingData(_.cloneDeep(tableData[e.target.name]));
  }, [tableData]);

  const closeEditingForm = useCallback(() => {
    setEditingData(null);
  }, []);

  const tableDataValues = useMemo(() => {
    return Object.values(tableData ?? {});
  }, [tableData]);

  const { role } = useSelector((state) => state.auth);
  const [authorized] = useState([ROLES.SUPERUSER.name, ROLES.ADMIN.name, ROLES.AUTHOR.name].includes(role));

  const handleUpdateMeta = useCallback(
    (updatedTagMeta) => {
      return updateClickCallback(updatedTagMeta);
    },
    [updateClickCallback]
  );

  const dataKeys = useMemo(() => {
    const keys = Object.keys(tableDataValues?.[0] ?? []);

    return keys.length > 0 ? keys : null;
  }, [tableDataValues]);

  const renderInputValue = useCallback(
    (value) => {
      return (typeof value === "boolean" ? String(value) : value) ?? "";
    },
    []
  );

  return dataKeys ? (
    <div>
      <div className="custom-table meta-table">
        <table className="table table-responsive">
          <thead className=" bg-white">
            <tr className="tagmeta-row sticky-top">
              {dataKeys.map((key) => (
                <th scope="col" className="align-middle col-auto" key={key}>{convertStringToHumanReadable(key)}</th>
              ))}
              {authorized && (
                <>
                  <th scope="col" className="controls align-middle col-auto" key="Save">
                    Edit
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody className="tagmeta-body">
            {renderingData.map(({ tagName, decimals, ...rest }) => (
              <tr key={tagName} className="tagmeta-row">
                <td className="col-auto align-middle tagName text-center">
                  <div>{tagName}</div>
                </td>
                {
                  Object.entries(rest).map(([key, value]) => (
                    <td key={key} className="col-auto align-middle text-center">
                      <div className="d-flex h-100 justify-content-center">
                        {renderInputValue(value) || <span className="disabled"> N/A</span>}
                      </div>
                    </td>)
                  )
                }
                <td className="col-auto align-middle text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <DecimalField tagName={tagName} name={"decimals"} value={decimals} editable={false} />
                  </div>
                </td>
                {authorized && <td className="col-auto align-middle controls text-center" >
                  <div className="d-flex justify-content-center align-items-center">
                    <button name={tagName} type="btn" className="btn btn-primary px-4 mx-2" onClick={openEditingForm}>Edit</button>
                  </div>
                </td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="container">
        <div className="row my-3 justify-content-between align-items-center">
          <Pagination
            className="fit-content"
            withElementsPerPage={true}
            withJump={true}
            data={tableDataValues}
            setRenderingData={setRenderingData}
          />
        </div>
      </div>
      {editingData && <TagMetaForm isOpen={editingData !== null} data={editingData || {}} close={closeEditingForm} onSaveAsync={asyncEdit && handleUpdateMeta} onSave={!asyncEdit && handleUpdateMeta} />}
    </div>
  ) : (
    <div className="text-center w-100">
      <p>No data Found</p>
    </div>
  );
};

export default TagsMetaTable;