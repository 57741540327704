import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { ROLES } from "../../auth/RoleManager";
import Loading from "../Loading/Loading";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const UserRow = ({ user, updateUserRole }) => {
  const [editingUser, setEditingUser] = useState(user);
  const [isWaiting, setWaiting] = useState(false);
  const [isToggled, setToggled] = useState(false);

  const currentUserEmail = useSelector((state) => state.auth.email);

  const handleRoleChange = useCallback(
    (event) => {
      setTimeout(() => {
        setToggled(false);
      }, 500);
      setEditingUser({ ...editingUser, role: event.currentTarget.name });
    },
    [editingUser]
  );

  const handleSave = useCallback(async () => {
    setWaiting(true);
    await updateUserRole(editingUser);
    setWaiting(false);
  }, [editingUser, updateUserRole]);

  const handleCancel = useCallback(() => {
    setEditingUser(user);
  }, [user]);

  const isEditing =
    editingUser.email === user.email && editingUser.role !== user.role;

  return (
    <tr className="user-data-row" key={user.email}>
      <td>{user.email}</td>
      <td>
        <Dropdown
          isOpen={isToggled}
          toggle={() => setToggled((state) => !state)}
          className=" mx-auto"
        >
          <DropdownToggle
            disabled={currentUserEmail === user.email}
            className="w-100 toggler"
            caret
          >
            {editingUser.role.charAt(0).toUpperCase() +
              editingUser.role.slice(1)}
          </DropdownToggle>
          <DropdownMenu className="user-role-selector mw-100">
            {Object.entries(ROLES).map(
              ([key, { name, description }]) =>
                name !== ROLES.SUPERUSER.name && (
                  <DropdownItem
                    key={key}
                    className="mw-10"
                    name={name}
                    onClick={handleRoleChange}
                    disabled={user.role === name}
                  >
                    <dt className="lead fw-bold">
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </dt>
                    <dd className="mw-100 text-wrap"> {description}</dd>
                  </DropdownItem>
                )
            )}
          </DropdownMenu>
        </Dropdown>
      </td>
      <td className="col-1">
        <button
          className="btn btn-primary"
          onClick={handleSave}
          disabled={!isEditing || isWaiting}
        >
          <Loading
            className={"mx-2"}
            isLoading={isWaiting}
            animationHeight={"100%"}
            animationWidth={"22px"}
            circleColor="#fff"
            animationOnly={true}
            scaleAnimation={"1.3"}
            backgroundColor="transparent"
          >
            Save
          </Loading>
        </button>
      </td>
      <td className="col-1">
        <button
          className="btn btn-primary"
          onClick={handleCancel}
          disabled={!isEditing || isWaiting}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};

export default UserRow;
