import _, { head, values } from "lodash";

const download = (data, fileName, type = "application/json") => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);

  const linkElement = document.createElement("a");
  linkElement.href = url;
  linkElement.download = fileName;
  linkElement.style.display = "none";

  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);

  URL.revokeObjectURL(url);
};

const writeFile = async (data) => {
  const fileHandler = await window.showSaveFilePicker({
    excludeAcceptAllOption: true,
    multiple: false,
    types: [
      {
        description: "JSON files",
        accept: { "application/json": [".json"] },
      },
    ],
  }),
    fileStream = await fileHandler.createWritable();
  await fileStream.write(
    new Blob([JSON.stringify(data)], {
      type: "application/json",
    })
  );
  await fileStream.close();
};

const uploadFile = async (
  uploadCompletedCallback,
  setUploadStatusCallback,
  onAbort
) => {
  if (window.self === window.top && window.showOpenFilePicker) {
    try {
      const fileHandler = await window.showOpenFilePicker({
        multiple: false,
        types: [
          {
            description: "JSON files",
            accept: { "application/json": [".json"] },
          },
          {
            description: "CSV files",
            accept: { "text/csv": [".csv"] },
          },
        ],
      });
      const file = await fileHandler[0].getFile();
      await readFile(
        file,
        setUploadStatusCallback,
        uploadCompletedCallback,
        file.type.split("/")[1]
      );
    } catch (err) {
      if (err.name === "AbortError") {
        onAbort?.();
      } else {
        console.log(err.message);
      }
    }
  } else {
    const uploader = document.createElement("input");

    uploader.type = "file";
    uploader.style.display = "none";
    uploader.accept = ".json,.csv";
    uploader.multiple = false;

    uploader.onchange = async (e) => {
      if (uploader.files.length > 0) {
        const file = uploader.files[0];
        readFile(
          file,
          setUploadStatusCallback,
          uploadCompletedCallback,
          file.type.split("/")[1]
        );
      }
    };
    uploader.oncancel = () => {
      onAbort?.();
    };
    document.body.appendChild(uploader);

    uploader.click();

    document.body.removeChild(uploader);
  }
};

const readFile = async (
  file,
  setStatusCallback,
  uploadCompletedCallback,
  type = "json"
) => {
  if (!file) {
    return;
  }

  const reader = new FileReader();
  reader.onloadstart = () => {
    setStatusCallback?.("Uploading...");
  };
  reader.onprogress = (event) => {
    if (event.lengthComputable) {
      const percentLoaded = Math.round((event.loaded / event.total) * 100);
      setStatusCallback?.(`Uploading... ${percentLoaded}%`);
    }
  };
  reader.onload = () => {
    try {
      if (type === "json") {
        const jsonData = JSON.parse(reader.result);
        uploadCompletedCallback(jsonData);
      } else if (type === "csv") {
        uploadCompletedCallback(convertCSVtoJSON(reader.result));
      }
      setStatusCallback?.("Upload completed");
    } catch (error) {
      setStatusCallback?.("Upload failed");
    }
  };
  reader.onerror = () => {
    setStatusCallback?.("Upload failed");
  };

  reader.readAsText(file, "UTF-8");
};

const updateObjects = (original, updated, updatedObjectIteration) => {
  const originalClone = _.cloneDeep(original);

  Object.entries(updated).forEach(([key, value]) => {
    updatedObjectIteration(key, value, original);
  });

  return { ...originalClone, ...updated };
};
const formatDate = (use24HourFormat, timestamp, timezone) => {
  let date = new Date(timestamp);

  return date.toLocaleString("en-CA", {
    timeZone: timezone,
    hourCycle: use24HourFormat ? "h23" : "h12",
  });
};

function sliceData(data, page, elementsPerPage, filter, withPageCount) {
  if (!data) return withPageCount ? [[], 0] : [];

  data = filterData(data, filter);

  let slicedData = [];
  if (data.length > 0) {
    const startElement = (page - 1) * elementsPerPage;
    slicedData = data.slice(
      startElement,
      Math.min(startElement + elementsPerPage, data.length)
    );
  }

  if (withPageCount) {
    return [slicedData, getTotalPageCount(data, elementsPerPage)];
  } else return slicedData;
}

function filterData(data, filter) {
  if (filter) {
    data = data.filter(filter);
  }

  return data;
}
const sleep = async (ms) => await new Promise((res) => setTimeout(res, ms));

const getTotalPageCount = (data, elementsPerPage) => {
  return (
    Math.floor(data.length / elementsPerPage) +
    (data.length % elementsPerPage === 0 ? 0 : 1)
  );
};

const convertStringToHumanReadable = (string) => {
  const underscoreSeparated = string
    .replace(/([a-z])([A-Z])/g, "$1_$2")
    .toLowerCase();
  const words = underscoreSeparated.split("_");
  const humanReadable = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return humanReadable;
};

// No nested object handling implemented
const convertSimpleJsonToCSVString = (jsonArray) => {
  let csv = "";
  const headers = Object.keys(jsonArray[0]);
  if (jsonArray.length > 0) {
    csv += `${headers.join(",")}\n`;

    jsonArray.forEach((jsonObject) => {
      let values = "";
      headers.forEach((header) => {
        if (jsonObject[header] == null)
          values += "\"\","
        else values += `"${jsonObject[header] instanceof Object ? JSON.stringify(jsonObject[header]).replaceAll('"', "\\\"").replaceAll(",", '|') : jsonObject[header]}",`
      });
      csv += `${values.substring(0, values.length - 1)}\n`;
    });
  }
  console.log(csv)

  return csv.substring(0, Math.max(0, csv.length - 1));
};

const convertCSVtoJSON = (csv) => {
  const lines = csv.split("\n"),
    headers = lines.shift().split(","),
    jsonObject = {};

  while (lines.length > 0) {
    let values = lines.shift();
    values = values.substring(1, values.length - 1).split("\",\"");

    const object = {};
    for (let i = 0; i < values.length; i++) {
      if (values[i] === "") values[i] = null;
      else if (values[i] === "true" || values[i] === "false")
        values[i] = values[i] === "true";
      else if (values[i].startsWith('{') && values[i].endsWith('}')) {
        values[i] = values[i] === null ? null : JSON.parse(values[i].replaceAll("\\\"", "\"").replaceAll("|", ","));
      }

      object[headers[i]] = values[i];
    }

    jsonObject[object["tagName"]] = object;
  }

  return jsonObject;
};

const flatNestedObject = (obj, prefix) => {
  let flattedObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value instanceof Object)
      flattedObj = { ...flattedObj, ...flatNestedObject(value, key) }
    else
      flattedObj = {
        ...flattedObj,
        [`${prefix ? `${prefix}.` : ""}${key}`]: value
      }
  }
  );

  return flattedObj;
}

export {
  writeFile,
  uploadFile as readFile,
  download,
  uploadFile,
  updateObjects,
  formatDate,
  filterData,
  sliceData,
  sleep,
  getTotalPageCount,
  convertStringToHumanReadable,
  convertSimpleJsonToCSVString,
  convertCSVtoJSON,
  flatNestedObject
};
