import { useCallback } from "react";

const ClosableItem = ({
    itemName,
    isClosable,
    bgClassName,
    btnClassName,
    txtClassName,
    closeClickedCallback,
    iconForCloseButton
}) => {

    const closeClicked=useCallback(()=>{
        closeClickedCallback(itemName);
    },[itemName,closeClickedCallback])

    return (
        <div className={`d-flex rounded align-items-center text-white ${bgClassName??"bg-secondary "}`}>

            <span className={`px-3 ${txtClassName??''}`}>
                {itemName ?? "Item"}
            </span>
            {(isClosable??true) &&
                <button
                    className={`btn ${btnClassName??"btn-secondary"}`}
                    onClick={closeClicked}>
                    {iconForCloseButton??"X"}
                </button>}
        </div>
    )
}

export default ClosableItem;